import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import ConfirmationPage from 'modules/auth/ConfirmationPage'
import { verify } from 'redux/slices/authSlice'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectStatus } from 'redux/selectors/authSelectors'

const EmailConfirmation = () => {
  const queryParams = window.location.search
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectStatus)

  useEffect(() => {
    if (queryParams) {
      setTimeout(() => {
        dispatch(verify(queryParams))
      }, 1000)
    }
  }, [queryParams])

  return isNotNilOrEmpty(status) ? <ConfirmationPage status={status} /> : null
}

export default EmailConfirmation
