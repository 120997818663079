import React, { useState } from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components/formComponents'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { filter, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import WallDesignInformationSection from 'modules/buildingDetails/components/WallDesignInformationSection'
import FloorsInformationSection from 'modules/buildingDetails/components/FloorsInformationSection'
import InfoWithIcon from 'modules/buildingDetails/components/InfoWithIcon'
import SectionNotCompletedInfo from './components/SectionNotCompletedInfo'
import { EditIcon, ExclamationIcon } from 'assets/icons'
import TextButton from 'components/atoms/TextButton'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import ResponsiblePerson from 'components/atoms/ResponsiblePerson'
import { selectFaultsList } from 'redux/selectors/faultsSelectors'

const BuildingUploadedInformation = () => {
  const building = useAppSelector(selectCurrentBuilding)

  const [showAllRp, setShowAllRp] = useState(false)
  const faultsList = useAppSelector(selectFaultsList)

  const isEvacuationStrategyChanged =
    filter(
      item =>
        pathOr(null, ['evacuationStrategyChange', 'value'], item) === 'yes' &&
        pathOr(null, ['status', 'value'], item) === 'reported',
      faultsList
    ).length > 0

  const floorsNumber = pathOr(0, ['floorsNumber'], building)
  const undergroundFloorsNumber = pathOr(
    0,
    ['undergroundFloorsNumber'],
    building
  )
  const groundFloor = pathOr(false, ['groundFloor'], building)
  const lowerGroundFloor = pathOr(false, ['lowerGroundFloor'], building)
  const responsiblePersons = pathOr([], ['responsiblePersons'], building)
  const responsiblePersonsCount = responsiblePersons.length
  // prettier-ignore
  const rpToShow = showAllRp ? responsiblePersons : responsiblePersons.slice(0, 1)
  const currentUser = useAppSelector(selectCurrentUser)
  const isFrs = pathOr(null, ['type'], currentUser) === 'emergency_service'

  return (
    <Wrapper>
      <Title>Uploaded information</Title>
      <Section>
        <SectionTitle>
          {responsiblePersonsCount > 1
            ? `Responsible persons (${responsiblePersonsCount})`
            : 'Responsible person'}
        </SectionTitle>
        {isNotNilOrEmpty(rpToShow) ? (
          rpToShow.map(rp => (
            <ResponsiblePerson key={pathOr('', ['email'], rp)} person={rp} />
          ))
        ) : !isFrs ? (
          <SectionNotCompletedInfo
            title='No responsible persons have been added yet'
            content='You need to add at least one responsible person’s details to submit any form.'
            icon={
              <IconWrapper>
                <EditIcon />
              </IconWrapper>
            }
            buttonLabel='Edit building'
            activeForm='edit_building'
          />
        ) : (
          <FrsNoButtonView>
            No responsible persons have been added yet
          </FrsNoButtonView>
        )}
        {responsiblePersonsCount > 1 && (
          <ButtonWrapper>
            <TextButton
              label={showAllRp ? 'Show less' : 'Show more'}
              onClick={() => setShowAllRp(prev => !prev)}
            />
          </ButtonWrapper>
        )}
      </Section>
      {isEvacuationStrategyChanged ? (
        <Section>
          <SectionTitle>Evacuation Strategy</SectionTitle>
          <InfoWithIcon
            title='Simultaneous evacuation policy'
            iconTrue={isEvacuationStrategyChanged && <ExclamationIcon />}
            condition={isEvacuationStrategyChanged}
            conditionTrue='Yes'
            conditionFalse='No'
            description='One or more of the reported faults has necessitated a temporary change in evacuation strategy from stay put to simultaneous.'
          />
        </Section>
      ) : null}

      <FloorsInformationSection
        floors={{
          floorsNumber,
          groundFloor,
          lowerGroundFloor,
          undergroundFloorsNumber
        }}
      />
      {isFrs && (
        <>
          <SectionTitle>Floor plans</SectionTitle>
          <FrsNoButtonView>No floor plans has been submitted</FrsNoButtonView>
        </>
      )}
      <WallDesignInformationSection building={building} />
    </Wrapper>
  )
}

export default BuildingUploadedInformation

const Wrapper = styled.div``

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
`

const Section = styled.div`
  margin-bottom: 26px;
`
const ButtonWrapper = styled.div`
  margin-top: 16px;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.copper};
`
const FrsNoButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
`
