import React, { useEffect, useMemo, useState } from 'react'
import FiltersSection from 'modules/myBuildings/components/filters/FiltersSection'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingFilters } from 'redux/selectors/dictionarySelectors'
import { keys, values as ramdaValues, equals, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import Checkbox from 'components/atoms/Checkbox'

const FaultReportEquipmentTypesFilter = ({
  onChange,
  counter,
  queryFilters
}) => {
  const buildingFilters = useAppSelector(selectBuildingFilters)
  const filters = pathOr({}, ['faultReportEquipmentTypes'], buildingFilters)
  const [values, setValues] = useState({})
  const [lastChosen, setLastChosen] = useState({})
  const isAnyOption = option => {
    return option.name === 'Any active reported fault'
  }

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      const query = keys(values)
        .map(key => (values[key] ? key : null))
        .filter(v => isNotNilOrEmpty(v))
        .join(',')
      onChange('fault_report_equipment_types', query)
    }
  }, [values])

  const handleChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!equals(queryFilters, lastChosen)) {
      const newValues = {}
      for (const key in values) {
        newValues[key] = queryFilters.includes(key)
      }
      setValues(newValues)
      setLastChosen(queryFilters)
    }
  }, [queryFilters])

  useEffect(() => {
    const newFilters = {}
    if (isNotNilOrEmpty(queryFilters)) {
      queryFilters.forEach(v => {
        newFilters[v] = true
      })
      setValues(prev => ({ ...prev, ...newFilters }))
    }
  }, [])

  const content = useMemo(() => {
    if (isNotNilOrEmpty(filters)) {
      return ramdaValues(filters).map((option: any) => {
        return !isAnyOption(option) ? (
          <Checkbox
            id={`fault_report_equipment_types-${option.id}`}
            key={`fault_report_equipment_types-${option.id}`}
            label={`${option.name} (${option.count})`}
            inputProps={{
              disabled: option.count === 0,
              onChange: e => handleChange(option.id, e.target.checked),
              checked: values[option.id] || false
            }}
          />
        ) : null
      })
    } else {
      return null
    }
  }, [filters, queryFilters, values])

  return (
    <FiltersSection title='Faults' counter={counter}>
      {content}
    </FiltersSection>
  )
}

export default FaultReportEquipmentTypesFilter
