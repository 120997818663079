import React, { useEffect, useState } from 'react'
import FormStep from 'modules/forms/components/FormStep'
import BuildingInformationStep from 'modules/forms/addBuildingForm/steps/BuildingInformationStep'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getFrsStations } from 'redux/slices/dictionarySlice'
import ResponsiblePersonsStep from 'modules/forms/addBuildingForm/steps/ResponsiblePersonsStep'
import {
  selectFinishedSteps,
  selectFormSteps,
  selectFormVisibleSteps
} from 'redux/selectors/formSelectors'
import ProgressBar from 'components/atoms/ProgressBar'
import styled from 'styled-components'
import FetchFromBuildingPassportModal from 'modules/forms/addBuildingForm/components/FetchFromBuildingPassportModal'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { has } from 'ramda'

const AddBuildingForm = () => {
  const dispatch = useAppDispatch()
  const visibleSteps = useAppSelector(selectFormVisibleSteps)
  const steps = useAppSelector(selectFormSteps)
  const finishedSteps = useAppSelector(selectFinishedSteps)
  const [fetchModalOpen, setFetchModalOpen] = useState(false)
  const user = useAppSelector(selectCurrentUser)
  const [fetchedBp, setFetchedBp] = useState(null)
  const canFetchBps = user && has('source', user) && user.source === 'building_passport'
  const handleFetchModalOpen = () => setFetchModalOpen(true)
  const handleFetchModalClose = () => setFetchModalOpen(false)

  const isVisible = stepName => visibleSteps.includes(stepName)

  useEffect(() => {
    dispatch(getFrsStations())
  }, [])

  const progressBarCount = (finishedSteps.length / steps.length) * 100

  return (
    <div>
      <ProgressBarWrapper>
        <ProgressBar value={progressBarCount} />
      </ProgressBarWrapper>
      {
        isVisible('building_information') && (
          <FormStep
            stepKey='building_information'
            title='Building Information'
            action={canFetchBps ? {
              label: 'Fetch from Building Passport',
              onClick: handleFetchModalOpen
            } : undefined}
          >
            <BuildingInformationStep fetchedBp={fetchedBp} />
          </FormStep>
        )
      }
      {
        isVisible('responsible_persons') && (
          <FormStep
            stepKey='responsible_persons'
            title='Responsible Person(s)'
          >
            <ResponsiblePersonsStep />
          </FormStep>
        )
      }
      <FetchFromBuildingPassportModal
        onFetch={setFetchedBp}
        open={fetchModalOpen}
        onClose={handleFetchModalClose}
      />
    </div>
  )
}

export default AddBuildingForm

const ProgressBarWrapper = styled.div`
  width: 80%;
  margin: 0 auto 20px;
`
