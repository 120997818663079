import React, { useState } from 'react'
import styled from 'styled-components'
import ContentPanel from 'components/atoms/ContentPanel'
import ReportedFaults from 'modules/buildingDetails/components/ReportedFaults'
import RectifiedFaults from 'modules/buildingDetails/components/RectifiedFaults'
import Tabs from 'components/atoms/Tabs'
import { ReportedFaultsIcon, RectifiedFaultsIcon } from 'assets/icons'
import { SectionTitle } from 'components/formComponents'
import ThanksMessageModal from 'modules/forms/components/ThanksMessageModal'
import FaultFormModal from 'modules/forms/faultsForm/FaultFormModal'
import RectForm from 'modules/forms/rectificationForm/RectForm'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { pathOr, propOr } from 'ramda'
import { selectCurrentUser } from 'redux/selectors/authSelectors'

const FaultsList = () => {
  const [fetchModalOpen, setFetchModalOpen] = useState(false)
  const building = useAppSelector(selectCurrentBuilding)
  const activeFaultCounter = building?.activeFaultReportsCount || 0
  const rectifiedFaultCounter = building?.rectifiedFaultReportsCount || 0
  const totalFaultsCounter = activeFaultCounter + rectifiedFaultCounter
  const currentUser = useAppSelector(selectCurrentUser)
  const isFrs =  pathOr(null, ['type'], currentUser) === 'emergency_service'
  const isBuildingActive = pathOr(null, ['buildingStatus', 'value'], building) === 'active'
  const buildingFaults: [] = propOr([], 'faultReports', building)

  const hasFaultReport = 
    buildingFaults.some(fault => {
      return (
        pathOr(null, ['rectification', 'status', 'value'], fault) !== 'rectified' &&
        pathOr(null, ['status', 'value'], fault) !== 'draft'
      )
    }
  )

  const tabs = [
    {
      key: 'reported',
      title: 'Reported',
      disabled: false,
      children: <ReportedFaults />,
      icon: <ReportedFaultsIcon />,
      counter: pathOr(0, ['activeFaultReportsCount'], building)
    },
    {
      key: 'rectified',
      disabled: rectifiedFaultCounter === 0,
      title: 'Rectified',
      children: <RectifiedFaults />,
      icon: <RectifiedFaultsIcon />,
      counter: pathOr(0, ['rectifiedFaultReportsCount'], building)
    }
  ]

  return (
    <ContentPanel>
      <ThanksMessageModal
        open={fetchModalOpen}
        onClose={() => setFetchModalOpen(false)}
        mode='fault'
      />
      <Header>
        <SectionTitle>
          Faults {totalFaultsCounter > 0 && `(${totalFaultsCounter})`}
        </SectionTitle>
        {!isFrs && (
          <Buttons>
            <FaultFormModal
              disabled={!isBuildingActive}
              triggerLabel='Report new'
            />
            <RectForm hasFaultReport={hasFaultReport} />
          </Buttons>
        )}
      </Header>
      <Tabs tabs={tabs} />
    </ContentPanel>
  )
}

export default FaultsList

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Buttons = styled.div`
  display: flex;
  gap: 15px;
`
