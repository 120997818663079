import React, { useEffect, useState } from 'react'
import FileInput from 'components/atoms/FileInput'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import styled from 'styled-components'
import { FormSeparator } from 'components/formComponents'
import { setActiveStep, updateFormValues } from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import {
  editBuilding,
  justUploadBuildingFile
} from 'redux/slices/buildingSlice'
import {
  selectCurrentBuilding,
  selectIsUploading
} from 'redux/selectors/buildingSelectors'
import { selectBuildingPlanFileTypeId } from 'redux/selectors/dictionarySelectors'
import AddedFile from 'modules/forms/plansForm/components/AddedFile'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import { pathOr } from 'ramda'
import ThanksMessageModal from 'modules/forms/components/ThanksMessageModal'
import { addFinishedFormStep } from 'utils/forms'
import TextButton from 'components/atoms/TextButton'
import Button from 'components/atoms/Button'

const BuildingPlansStep = () => {
  const [thanksModalOpen, setThanksModalOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const dispatch = useAppDispatch()
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const fileTypeId = useAppSelector<any>(selectBuildingPlanFileTypeId)
  const [file, setFile] = useState<any>({})
  const [addedFile, setAddedFile] = useState<any>({})
  const isUploading = useAppSelector(selectIsUploading)
  const formValues = useAppSelector(selectActiveFormValues)

  const fileExtension = file.path?.slice(-3)
  const isExtensionAllowed =
    isNotNilOrEmpty(fileExtension) && ['pdf', 'dwg'].includes(fileExtension)

  useEffect(() => {
    const buildingPlan = formValues.files?.find(
      file => file.fileTypeId === fileTypeId
    )
    if (isNotNilOrEmpty(buildingPlan)) {
      setAddedFile(buildingPlan)
    }
  }, [])

  const isFormSubmitted = pathOr(null, ['floorPlansStatus', 'value'], currentBuilding) === 'submitted'

  const submit = async () => {
    const result = await dispatch(editBuilding({
      ...formValues,
      submitFloorPlans: true,
      name: currentBuilding.name,
      id: currentBuilding.id,
      buildingFormsState: addFinishedFormStep(
        'plans',
        'building_plans',
        formValues?.buildingFormsState || []
      )
    }))

    if (isNotNilOrEmpty(result.payload)) {
      setThanksModalOpen(true)
    }
  }

  const handleUpload = async () => {
    const result = await dispatch(
      justUploadBuildingFile({
        fileTypeId,
        file,
        buildingId: currentBuilding.id
      })
    )
    const payload = result.payload

    if (isNotNilOrEmpty(payload)) {
      const files = pathOr([], ['files'], formValues)
      const filtered = files.filter(
        file => pathOr('', ['fileTypeId'], file) !== fileTypeId
      )
      dispatch(updateFormValues({ files: [...filtered, payload] }))
      setAddedFile(payload)
    }
  }

  const handleGoBack = () => {
    dispatch(setActiveStep('floor_plans'))
  }

  const handleValidate = () => {
    if (isNotNilOrEmpty(addedFile)) {
      setShowError(false)
      submit()
    } else {
      setShowError(true)
    }
  }

  const handleRemove = () => {
    const files = pathOr([], ['files'], formValues)
    const filtered = files.filter(
      file => pathOr('', ['fileTypeId'], file) !== fileTypeId
    )
    setAddedFile({})
    setFile({})
    dispatch(updateFormValues({ files: filtered }))
  }

  return (
    <div>
      <ThanksMessageModal
        open={thanksModalOpen}
        onClose={() => setThanksModalOpen(false)}
        mode='floorPlans'
      />
      <ExpandableInfo
        title='What information should be provided?'
        shortDescription='A separate single page building plan which shows the building and its immediate surroundings.'
      >
        <InfoContent>
          <p>
            The building plan will enable firefighters to orientate themselves
            upon arrival and must provide information on access and key
            firefighting both inside and outside the building.
          </p>
          <p>
            The full list of information to be provided on the building plan is
            defined in the Regulations and includes all of the following:
          </p>
          <ul>
            <li>environs of the building</li>
            <li>
              details of the use of the building, for example for commercial or
              residential purposes
            </li>
            <li>access for fire and rescue appliances</li>
            <li>dimensions of the building</li>
            <li>
              information on the number of storeys of the building and the
              number of basement levels (if any)
            </li>
            <li>
              information regarding the presence of maisonettes or scissor
              section flats
            </li>
            <li>inlets for:</li>
            <ul>
              <li>dry-rising mains</li>
              <li>wet-rising mains</li>
            </ul>
            <li>location of shut-off controls for any sprinklers</li>
            <li>access points for the building</li>
            <li>location of:</li>
            <ul>
              <li>secure information box</li>
              <li>controls for any smoke control system</li>
              <li>any firefighting shaft</li>
              <li>main stairways in the building</li>
              <li>controls for any evacuation alert system</li>
            </ul>
          </ul>
        </InfoContent>
      </ExpandableInfo>
      {isNotNilOrEmpty(addedFile) ? (
        <AddedFile onRemove={handleRemove} file={addedFile} />
      ) : (
        <FileInput
          onChange={setFile}
          accept='.pdf,.dwg'
          isLoading={isUploading}
          isUploaded={isNotNilOrEmpty(file)}
        />
      )}
      {!isExtensionAllowed && isNotNilOrEmpty(file) && (
        <Error>File extension not allowed. Please use PDF od DWG file.</Error>
      )}
      {isNilOrEmpty(addedFile) && (
        <AddFileButtonWrapper>
          <TextButton
            label='Upload selected file'
            onClick={handleUpload}
            disabled={!isExtensionAllowed || isNilOrEmpty(file)}
          />
        </AddFileButtonWrapper>
      )}
      {showError && isNilOrEmpty(addedFile) && (
        <Error>You have to upload plan of your building</Error>
      )}
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>Back</Button>
        <Button onClick={handleValidate}>
          {isFormSubmitted ? 'Save Changes' : 'Submit Plans'}
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default BuildingPlansStep

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ul {
    list-style: disc;
    font-weight: bold;
    padding-left: 10px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`

const AddFileButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Error = styled.div`
  font-size: 14px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.error};
`
