import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Table from 'components/atoms/Table'
import { formatDate } from 'utils/date'
import ReportedNoFaults from 'modules/buildingDetails/components/ReportedNoFaults'
import Status from 'components/atoms/Status'
import { find, pathOr, propEq, propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { DeleteIcon, ReportedFaultsIcon } from 'assets/icons'
import EyeIcon from 'assets/icons/EyeIcon'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import FaultPreviewModal from './FaultPreviewModal'
import { selectFaultsDictionary } from 'redux/selectors/dictionarySelectors'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import qs from 'qs'
import { getFaults } from 'redux/slices/faultsSlice'
import FaultFormModal from 'modules/forms/faultsForm/FaultFormModal'
import RemoveFaultModal from './RemoveFaultModal'
import RectForm from 'modules/forms/rectificationForm/RectForm'
import {
  selectFaultsList,
  selectFaultsListPagination
} from 'redux/selectors/faultsSelectors'
import { selectCurrentUser } from 'redux/selectors/authSelectors'

const ReportedFaults = () => {
  const dispatch = useAppDispatch()
  const [openDeleteFaultModal, setOpenDeleteFaultModal] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [currentFault, setCurrentFault] = useState({})
  const faultsDictionary = useAppSelector(selectFaultsDictionary)
  const currentUser = useAppSelector(selectCurrentUser)
  const isFrs = pathOr(null, ['type'], currentUser) === 'emergency_service'

  const { id } = useParams()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const list = useAppSelector(selectFaultsList)
  const pagination = useAppSelector(selectFaultsListPagination)

  useEffect(() => {
    const query = {
      limit: 20,
      page: 1,
      filter: { is_rectified: false }
    }
    dispatch(
      getFaults({ id, query: qs.stringify({ ...searchParams, ...query }) })
    )
    setSearchParams(qs.stringify(query))
  }, [])

  const handleOpenPreview = (_, row, equipmentType) => {
    setOpenPreview(true)
    setCurrentFault({ ...row, equipmentType })
  }

  const handleClosePreview = () => {
    setOpenPreview(false)
  }

  const handleDeleteFaultModalOpen = (_, row) => {
    setOpenDeleteFaultModal(true)
    setCurrentFault(row)
  }

  const handleDeleteFaultModalClose = () => {
    setOpenDeleteFaultModal(false)
  }

  const handlePageChange = (_, page) => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    dispatch(getFaults({ id, query: qs.stringify({ ...parsedQuery, page }) }))
    setSearchParams(qs.stringify({ ...parsedQuery, page }))
  }

  const actionHeaders = isFrs ? [] : [{ children: 'Actions' }]

  const getActionCell = row => {
    const isReported = row.status?.value === 'reported'
    const isRectifyDraft =
      pathOr({}, ['rectification', 'status', 'value'], row) === 'draft'
    return isFrs
      ? []
      : [
          {
            children: (
              <ButtonWrapper>
                {isReported ? (
                  isRectifyDraft ? (
                    <RectForm fault={row} triggerLabel='Continue' />
                  ) : (
                    <RectForm fault={row} triggerLabel='Rectify' />
                  )
                ) : (
                  <FaultFormModal triggerLabel='Continue' fault={row} />
                )}
              </ButtonWrapper>
            )
          }
        ]
  }

  const headers = [
    { children: '' },
    { children: 'Id' },
    { children: 'Fault' },
    { children: 'Status' },
    { children: 'Reported' },
    ...actionHeaders,
    { children: '' }
  ]

  const rows = list && list.map(row => {
    const equipmentType = find(
      propEq('id', propOr('', 'equipmentTypeId', row)),
      faultsDictionary
    )
    const isDraft = row.status?.value === 'draft'

    return {
      cells: [
        {
          children: <ReportedFaultsIconWrapper />
        },
        {
          children: <IdWrapper>{propOr('-', 'serial', row)}</IdWrapper>
        },
        { children: propOr('-', 'name', equipmentType) },
        {
          children: (
            <Status
              status={pathOr('draft', ['status', 'value'], row)}
              withIcon
            />
          )
        },
        { children: <Date>{formatDate(propOr('-', 'createdAt', row))}</Date> },
        ...getActionCell(row),
        {
          children: (
            <ActionsWrapper>
              <EyeIconWrapper
                disabled={isDraft}
                isDraft={isDraft}
                onClick={
                  !isDraft
                    ? e => handleOpenPreview(e, row, equipmentType)
                    : () => {}
                }
              />
              {!isFrs && (
                <>
                  <DeleteIconWrapper
                    disabled={!isDraft}
                    isDraft={isDraft}
                    onClick={
                      isDraft
                        ? e => handleDeleteFaultModalOpen(e, row)
                        : () => {}
                    }
                  />
                  <RemoveFaultModal
                    open={openDeleteFaultModal}
                    onClose={handleDeleteFaultModalClose}
                    id={propOr('', 'id', currentFault)}
                    buildingId={id || ''}
                  />
                </>
              )}
              <FaultPreviewModal
                open={openPreview}
                onClose={handleClosePreview}
                fault={currentFault}
              />
            </ActionsWrapper>
          )
        }
      ]
    }
  })

  return (
    <>
      {isNotNilOrEmpty(list) ? (
        <Table
          headers={headers}
          rows={rows}
          pagination={pagination}
          onPageChange={handlePageChange}
        />
      ) : (
        <ReportedNoFaults />
      )}
    </>
  )
}

export default ReportedFaults

const Date = styled.div`
  color: ${({ theme }) => theme.colors.lightTrout};
`
const IdWrapper = styled.div`
  color: ${({ theme }) => theme.colors.copper};
  display: flex;
  align-items: center;
  gap: 8px;
`
const ReportedFaultsIconWrapper = styled(ReportedFaultsIcon)`
  fill: ${({ theme }) => theme.colors.copper};
`
const EyeIconWrapper = styled(EyeIcon)`
  fill: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.lightTrout};
  margin: 0 4px;
  cursor: ${({ isDraft }) => (isDraft ? 'default' : 'pointer')};
`
const DeleteIconWrapper = styled(DeleteIcon)`
  margin: 0 4px;
  cursor: ${({ isDraft }) => (isDraft ? 'pointer' : 'default')};
`
const ButtonWrapper = styled.div`
  width: 100%;

  button {
    width: 100%;
  }

  margin-right: 20px;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  width: 100%;
`
