import React from 'react'
import Icon from './Icon'

export const WallsIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='25'
      height='24'
      viewBox='0 0 25 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.33364 7.50475C9.06012 7.50475 8.83838 7.72649 8.83838 8.00001C8.83838 8.27353 9.06012 8.49526 9.33364 8.49526H10.3336C10.6072 8.49526 10.8289 8.27353 10.8289 8.00001C10.8289 7.72649 10.6072 7.50475 10.3336 7.50475H9.33364Z' />
      <path d='M8.83838 12C8.83838 11.7265 9.06012 11.5048 9.33364 11.5048H10.3336C10.6072 11.5048 10.8289 11.7265 10.8289 12C10.8289 12.2735 10.6072 12.4953 10.3336 12.4953H9.33364C9.06012 12.4953 8.83838 12.2735 8.83838 12Z' />
      <path d='M9.33364 15.5048C9.06012 15.5048 8.83838 15.7265 8.83838 16C8.83838 16.2735 9.06012 16.4953 9.33364 16.4953H10.3336C10.6072 16.4953 10.8289 16.2735 10.8289 16C10.8289 15.7265 10.6072 15.5048 10.3336 15.5048H9.33364Z' />
      <path d='M13.8384 8.00001C13.8384 7.72649 14.0601 7.50475 14.3336 7.50475H15.3336C15.6072 7.50475 15.8289 7.72649 15.8289 8.00001C15.8289 8.27353 15.6072 8.49526 15.3336 8.49526H14.3336C14.0601 8.49526 13.8384 8.27353 13.8384 8.00001Z' />
      <path d='M14.3336 11.5048C14.0601 11.5048 13.8384 11.7265 13.8384 12C13.8384 12.2735 14.0601 12.4953 14.3336 12.4953H15.3336C15.6072 12.4953 15.8289 12.2735 15.8289 12C15.8289 11.7265 15.6072 11.5048 15.3336 11.5048H14.3336Z' />
      <path d='M13.8384 16C13.8384 15.7265 14.0601 15.5048 14.3336 15.5048H15.3336C15.6072 15.5048 15.8289 15.7265 15.8289 16C15.8289 16.2735 15.6072 16.4953 15.3336 16.4953H14.3336C14.0601 16.4953 13.8384 16.2735 13.8384 16Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.33106 21.4953C5.33192 21.4953 5.33277 21.4953 5.33362 21.4953C5.33448 21.4953 5.33533 21.4953 5.33618 21.4953H19.3311C19.3319 21.4953 19.3328 21.4953 19.3336 21.4953C19.3345 21.4953 19.3353 21.4953 19.3362 21.4953H21.3336C21.6072 21.4953 21.8289 21.2735 21.8289 21C21.8289 20.7265 21.6072 20.5048 21.3336 20.5048H19.8289V5.00002C19.8289 4.33823 19.566 3.70356 19.098 3.2356C18.6301 2.76765 17.9954 2.50476 17.3336 2.50476H7.33362C6.67184 2.50476 6.03716 2.76765 5.56921 3.2356C5.10126 3.70356 4.83837 4.33823 4.83837 5.00002V20.5048H3.33363C3.06011 20.5048 2.83838 20.7265 2.83838 21C2.83838 21.2735 3.06011 21.4953 3.33363 21.4953H5.33106ZM6.26961 3.936C6.5518 3.65381 6.93454 3.49527 7.33362 3.49527H17.3336C17.7327 3.49527 18.1154 3.65381 18.3976 3.936C18.6798 4.2182 18.8384 4.60093 18.8384 5.00002V20.5048H5.82888V5.00002C5.82888 4.60093 5.98742 4.2182 6.26961 3.936Z'
      />
    </Icon>
  )
}

export default WallsIcon
