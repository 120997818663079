import React from 'react'
import styled from 'styled-components'
import FloorBadges from 'modules/buildingDetails/components/FloorBadges'

type PlanThumbnailTypes = {
  plan: {
    url: string
    thumbnailUrl: string
    displayName: string
    floorNumbers: string[]
  }
  isBuildingPlan?: boolean
}

const PlanThumbnail = ({ plan, isBuildingPlan = false } : PlanThumbnailTypes) => {
  return (
    <Wrapper href={plan.url} target='_blank' rel='noreferrer'>
      <Image url={plan.thumbnailUrl} />
      <Details>
        <FileName>
          {plan.displayName}
        </FileName>
        <FloorsWrapper>
          <FloorsLabel>
            {
              isBuildingPlan
                ? 'Building plan'
                : 'Floors'
            }
          </FloorsLabel>
          {!isBuildingPlan && <FloorBadges floorNumbers={plan.floorNumbers} />}
        </FloorsWrapper>
      </Details>
    </Wrapper>
  )
}

export default PlanThumbnail

const Wrapper = styled.a`
  display: block;
  height: 170px;
  filter: drop-shadow(0px 0px 4px rgba(101, 118, 184, 0.08)) drop-shadow(0px 2px 4px rgba(101, 118, 184, 0.12)) drop-shadow(0px 8px 16px rgba(101, 118, 184, 0.06)) drop-shadow(0px 1px 2px rgba(101, 118, 184, 0.06));
  border-radius: 4px;
  overflow: hidden;
  max-width: 200px;
  min-width: 200px;
`

const Image = styled.div`
  height: 100px;
  width: 200px;
  background: ${({ url }) => `transparent url("${url}") no-repeat center/cover`};
`

const Details = styled.div`
  height: 70px;
  background-color: #F7F8FD;
  padding: 5px;
  border-radius: 0 0 4px 4px;
`

const FileName = styled.div`
  width: 100%;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 26px;
  font-size: 14px;
`

const FloorsWrapper = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FloorsLabel = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
  text-transform: uppercase;
`
