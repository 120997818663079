import React from 'react'
import Icon from './Icon'

export const ArrowDownIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='12'
      height='8'
      viewBox='0 0 12 8'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.350098 1.50705L6.0071 7.16405L11.6641 1.50705L10.9571 0.800049L6.0071 5.75005L1.0571 0.800049L0.350098 1.50705Z'
      />
    </Icon>
  )
}

export default ArrowDownIcon
