import React, { ReactNode, ReactElement } from 'react'
import styled from 'styled-components'
import { MenuItem, Select } from '@mui/material'
import { Controller } from 'react-hook-form'
import { find, propEq } from 'ramda'
import { CloseIcon } from 'assets/icons'
import { isNotNilOrEmpty } from 'utils/ramda'

type OptionType = {
  label: string | ReactNode | ReactElement
  value: string
}

type SelectTypes = {
  options: OptionType[]
  label: string | ReactNode | ReactElement
  name: string
  disabled?: boolean
  placeholder?: string
  multiple?: boolean
  control: any
  noMarginBottom?: boolean
  error?: string
  onItemDeselect?: any
}

export const SelectMui = (props: SelectTypes) => {
  const {
    options,
    label,
    name,
    disabled,
    placeholder,
    multiple,
    control,
    noMarginBottom,
    error,
    onItemDeselect
  } = props

  const findLabel = (value) => {
    const selectedOption = find(propEq('value', value), options)
    return selectedOption
      ? selectedOption.label
      : value
  }

  const renderOptions = options.map(option => (
    <MenuItem
      key={option.value}
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ))

  const hasDeselectFunction = isNotNilOrEmpty(onItemDeselect)

  const handleFloorDeselect = value => e => {
    e.stopPropagation()
    onItemDeselect(value)
  } 

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>
          {label}
        </Label>
      </LabelWrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledSelect
            {...field}
            size='small'
            multiple={multiple}
            placeholder={placeholder}
            disabled={disabled}
            noMarginBottom={noMarginBottom}
            renderValue={selected => typeof selected === 'object'
              ? (
                <StyledBox>
                  {selected?.map((value) => (
                    <StyledChip key={value}>
                      {findLabel(value)}
                      {
                        hasDeselectFunction && (
                          <CloseIconWrapper onMouseDown={handleFloorDeselect(value)}>
                            <RedCloseIcon />
                          </CloseIconWrapper>
                        )
                      }
                    </StyledChip>
                  ))}
                </StyledBox>
              )
              : findLabel(selected)
            }
          >
            {renderOptions}
          </StyledSelect>
        )}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

SelectMui.defaultProps = {
  validate: null,
  variant: 'outlined',
  options: [],
  name: '',
  label: '',
  onChange: () => {
  },
  value: '',
  required: false,
  disabled: false,
  placeholder: '',
  withDelete: false,
  onDelete: () => {
  },
  noMargin: false,
  multiple: false,
  disabledValues: null
}

export default SelectMui

const Wrapper = styled.div`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledSelect = styled(Select)`
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  min-height: 44px;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? 0 : '15px'};

  input {
    height: 100%;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const StyledBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const StyledChip = styled.div`
  display: flex;
  padding: 3px 10px;
  gap: 10px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #EEBEB1;
  background-color: #FAF2F0;
  font-size: 14px;
  cursor: default;

`
const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RedCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  fill: ${({ theme }) => theme.colors.copper};
  cursor: pointer;

`
const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  align-items: center;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
