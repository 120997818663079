import React, { MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import { Modal as MuiModal } from '@mui/material'
import { CloseIcon } from 'assets/icons'
import { omit } from 'ramda'
import Button from 'components/atoms/Button'

type ModalTypes = {
  title: string | ReactNode
  children?: ReactNode
  withSubmit?: boolean
  withCancel?: boolean
  withSummary?: boolean
  submitButtonText?: string
  cancelButtonText?: string
  summaryButtonText?: string
  onCancel?: () => void
  onSubmit?: () => void
  goTo?: () => void
  disabled?: boolean
  onClose?: MouseEventHandler
  hideClose?: () => void
  open?: boolean
}

const Modal = (props: ModalTypes) => {
  const {
    title,
    children,
    withSubmit,
    withCancel,
    withSummary,
    submitButtonText,
    cancelButtonText,
    summaryButtonText,
    onCancel,
    onSubmit,
    goTo,
    disabled,
    onClose,
    hideClose
  } = props

  const MuiModalProps = omit(
    ['hideClose', 'submitButtonText', 'cancelButtonText'],
    props
  )

  return (
    // @ts-ignore
    <MuiModal {...MuiModalProps} disableScrollLock>
      <StyledModal onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          {!hideClose && (
            <CloseIconWrapper onClick={onClose}>
              <CloseIcon />
            </CloseIconWrapper>
          )}
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        {(withSubmit || withCancel || withSummary) && (
          <ButtonsWrapper>
            {withCancel && (
              <Button variant='secondary' onClick={onCancel}>
                {cancelButtonText}
              </Button>
            )}
            {withSummary && (
              <Button variant='secondary' onClick={goTo}>
                {summaryButtonText}
              </Button>
            )}
            {withSubmit && (
              <Button onClick={onSubmit} disabled={disabled}>
                {submitButtonText}
              </Button>
            )}
          </ButtonsWrapper>
        )}
      </StyledModal>
    </MuiModal>
  )
}

Modal.defaultProps = {
  submitButtonText: 'Submit',
  cancelButtonText: 'Cancel',
  onCancel: () => {},
  onSubmit: () => {},
  onClose: () => {}
}

export default Modal

const StyledModal = styled.div`
  box-sizing: border-box;
  min-width: 435px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  max-height: 95vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 8px;
`

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 27px;
  font-weight: bold;
`

const CloseIconWrapper = styled.div`
  width: 30px;
  font-size: 20px;
  cursor: pointer;
`

const ButtonsWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  button {
    min-width: 100px;
    width: fit-content;
  }
`

const ModalContent = styled.div`
  overflow: auto;
  flex: 1;
  padding: 20px;
`
