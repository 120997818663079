import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { signUp } from 'redux/slices/authSlice'
import { Link, useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { FieldValues, useForm } from 'react-hook-form'
import Input from 'components/atoms/Input'
import { boolean, string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { selectAuthLoading } from 'redux/selectors/authSelectors'
import PasswordInput, {
  passwordRegex
} from 'components/atoms/password/PasswordInput'
import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'

const schema = {
  type: string().oneOf(['user', 'emergency_service'], 'Incorrect type'),
  email: string().required('Email is required').email('Incorrect Email'),
  password: string()
    .required('Password is required')
    .matches(passwordRegex, 'Incorrect Password'),
  first_name: string()
    .required('First name is required')
    .min(2, 'Mininum 2 characters')
    .matches(/^[a-zA-Z]+$/, { message: 'Incorrect value' }),
  last_name: string()
    .required('Last name is required')
    .min(2, 'Mininum 2 characters')
    .matches(/^[a-zA-Z]+$/, { message: 'Incorrect value' }),
  phone_number: string()
    .nullable()
    .test(
      'match',
      'Phone number is incorrect',
      value => !value || /^\d*$/.test(value)
    )
    .test(
      'max',
      'Phone number should not be longer than 10 characters',
      value => !value || value.length <= 11
    )
    .test(
      'min',
      'Phone number should be at least 9 characters',
      value => !value || value.length >= 10
    ),
  company: string().required('FRS station is required'),
  postal_code: string().required('Postal Code is required'),
  city: string().required('City is required'),
  street: string().required('Street is required'),
  disclaimer: boolean().oneOf([true], 'Required'),
  updates: boolean()
}

const RegisterFrsForm = () => {
  const isLoading = useAppSelector(selectAuthLoading)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const defaultValues : FieldValues = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    company: '',
    street: '',
    postal_code: '',
    city: '',
    disclaimer: false,
    updates: false
  }

  const label = (
    <>
      I agree to<Link to={PATHS.privacy}>Building Passport Policy</Link>&
      <Link to={PATHS.terms}>Terms of use</Link>
    </>
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(object().shape(schema))
  })

  const handleRegisterUser = async data => {
    const registerData = {
      ...data,
      type: 'emergency_service',
      source: 'info_exchange'
    }
    const result = await dispatch(signUp(registerData))
    if (result.payload) {
      navigate(PATHS.registerConfirm)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleRegisterUser)}>
      <Input
        register={register}
        name='email'
        error={errors.email?.message as string}
        label='Email'
      />
      <PasswordInput
        register={register}
        error={errors.password?.message as string}
        name='password'
        label='Password'
        inputValue={watch('password')}
        showRequirements
      />
      <Details>
        <DetailsHeader>Personal Details</DetailsHeader>
      </Details>
      <Name>
        <Input
          register={register}
          name='first_name'
          error={errors.first_name?.message as string}
          label='First Name'
        />
        <Input
          register={register}
          name='last_name'
          error={errors.last_name?.message as string}
          label='Last Name'
        />
      </Name>
      <Input
        register={register}
        name='phone_number'
        error={errors.phone_number?.message as string}
        label='Phone Number'
        leftIcon={<PhonePrefixWrapper>+44</PhonePrefixWrapper>}
      />
      <Details>
        <DetailsHeader>Contact Details</DetailsHeader>
      </Details>
      <Input
        register={register}
        name='company'
        error={errors.company?.message as string}
        label='FRS Station'
      />
      <Input
        register={register}
        name='street'
        error={errors.street?.message as string}
        label='Address'
      />
      <Input
        register={register}
        name='postal_code'
        error={errors.postal_code?.message as string}
        label='Postal Code'
      />
      <Input
        register={register}
        name='city'
        error={errors.city?.message as string}
        label='City'
      />
      <CheckboxWrapper>
        <Checkbox
          id='disclaimer'
          label={label}
          error={errors.disclaimer?.message as string}
          inputProps={register('disclaimer')}
        />
        <Checkbox
          id='updates'
          label='I want to be kept up to date via occasional emails highlighting changes to legislation, new features and special offers'
          error={errors.updates?.message as string}
          inputProps={register('updates')}
        />
      </CheckboxWrapper>
      <ButtonWrapper>
        <Button isLoading={isLoading} disabled={isLoading} type='submit'>
          Sign up
        </Button>
        <ButtonSeparator>
          <span>Already have Building Passport account?</span>
        </ButtonSeparator>
        <Button variant='outlined' onClick={() => navigate('/login')}>
          Log in
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default RegisterFrsForm

const CheckboxWrapper = styled.div`
  margin-top: 30px;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
  }
`
const Details = styled.div`
  font-size: 12px;
  margin: 20px 0 10px;
`
const DetailsHeader = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.copper};
  padding: 10px 0;
`
const Name = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
const ButtonSeparator = styled.div`
  display: flex;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.trout};
  padding: 20px 0;

  span {
    padding: 0 6px;
  }

  :before,
  :after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    margin: auto;
  }
`

const PhonePrefixWrapper = styled.div`
  color: ${({ theme }) => theme.colors.disabled};
`
