import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { getFloorOptions } from 'utils/buildings'
import styled, { css } from 'styled-components'

const FloorFilters = ({ onChange } : { onChange : (selectedFloor: string) => void}) => {
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const [selectedFloor, setSelectedFloor] = useState('all')

  useEffect(() => {
    onChange(selectedFloor)
  }, [selectedFloor])

  const floorOptions = useMemo(() => {
    return currentBuilding && getFloorOptions(currentBuilding, true)
  }, [currentBuilding])

  return (
    <FloorSelectWrapper>
      <FloorSelectButton
        onClick={() => setSelectedFloor('all')}
        active={selectedFloor === 'all'}
      >
        All floors
      </FloorSelectButton>
      {
        floorOptions.map(option => {
          return (
            <FloorSelectButton
              key={option.label}
              active={selectedFloor === option.value}
              onClick={() => setSelectedFloor(option.value)}
            >
              {option.label}
            </FloorSelectButton>
          )
        })
      }
    </FloorSelectWrapper>
  )
}

export default FloorFilters

const FloorSelectWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`

const FloorSelectButton = styled.div`
  border-radius: 4px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.colors.haiti};
  background-color: #dfe4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 3px 10px 3px 0;
  cursor: pointer;
  transition: all .3s;
  width: 25px;

  &:first-of-type {
    width: 70px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }

  ${({ active, theme }) => active && css`
    background-color: ${theme.colors.primary};
    color: #fff;
  `}
`
