import React from 'react'
import Icon from './Icon'

export const ShareIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='4' y='4' width='48' height='48' rx='24' fill='#FAE4DE' />
      <path
        d='M26.4995 29.5002L36.9995 19.0002M26.6271 29.8282L29.2552 36.5862C29.4867 37.1816 29.6025 37.4793 29.7693 37.5662C29.9139 37.6415 30.0862 37.6416 30.2308 37.5664C30.3977 37.4797 30.5139 37.1822 30.7461 36.5871L37.3364 19.6994C37.5461 19.1622 37.6509 18.8936 37.5935 18.722C37.5437 18.5729 37.4268 18.456 37.2777 18.4062C37.1061 18.3488 36.8375 18.4536 36.3003 18.6633L19.4126 25.2536C18.8175 25.4858 18.52 25.602 18.4333 25.7689C18.3581 25.9135 18.3582 26.0858 18.4335 26.2304C18.5204 26.3972 18.8181 26.513 19.4135 26.7445L26.1715 29.3726C26.2923 29.4196 26.3527 29.4431 26.4036 29.4794C26.4487 29.5115 26.4881 29.551 26.5203 29.5961C26.5566 29.647 26.5801 29.7074 26.6271 29.8282Z'
        stroke='#D14925'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='4'
        y='4'
        width='48'
        height='48'
        rx='24'
        stroke='#FCF4F2'
        strokeWidth='8'
      />
    </Icon>
  )
}

export default ShareIcon
