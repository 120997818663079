import React, { useMemo } from 'react'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { useAppSelector } from 'redux/hooks'
import { selectFrsStations } from 'redux/selectors/dictionarySelectors'
import ProgressBar from 'components/atoms/ProgressBar'
import { useNavigate } from 'react-router-dom'
import TextButton from 'components/atoms/TextButton'
import { getBuildingAddress } from 'utils/building'
import theme from 'utils/theme'
import { RectifiedFaultsIcon, ReportedFaultsIcon } from 'assets/icons'

const MyBuildingsListItem = ({ building }) => {
  // const [open, setOpen] = useState(false)
  const frsStations = useAppSelector(selectFrsStations)
  const navigate = useNavigate()
  const floorsCompletionPercentage: number = propOr(
    '',
    'floorsCompletionPercentage',
    building
  )
  const wallDesignCompletionPercentage: number = propOr(
    '',
    'wallDesignCompletionPercentage',
    building
  )

  const frsName = frsStations.find(
    station => station.id === building.frsId
  )?.name

  const faultsReported = building?.activeFaultReportsCount || 0

  const faultsRectified = building?.rectifiedFaultReportsCount || 0
  // const handleRemoveModalOpen = () => {
  //   setOpen(true)
  // }
  // const handleRemoveModalClose = () => {
  //   setOpen(false)
  // }
  const handleRedirect = () => {
    navigate(`/buildings/${building.id}`)
  }
  const address = useMemo(() => {
    return getBuildingAddress(building)
  }, [building])

  return (
    <Wrapper>
      <DetailsWrapper>
        <HeaderWrapper>
          <FrsName>{frsName}</FrsName>
          {/* <Menu */}
          {/*  id={`menu-${building.id}`} */}
          {/*  items={[ */}
          {/*    { label: 'Remove building', onClick: handleRemoveModalOpen } */}
          {/*  ]} */}
          {/* /> */}
        </HeaderWrapper>
        <BuildingName>{building.name}</BuildingName>
        <BuildingAddress>{address}</BuildingAddress>
      </DetailsWrapper>
      <StatWrapper>
        <ProgressItemWrapper>
          <ProgressItem>
            <span>Plans</span>
            <ProgressBar value={floorsCompletionPercentage} />
          </ProgressItem>
          <ProgressItem>
            <span>Wall design</span>
            <ProgressBar value={wallDesignCompletionPercentage} />
          </ProgressItem>
          <ProgressItem>
            <FaultsIconWrapper>
              <FaultsText>Faults</FaultsText>
              <FaultIcon empty={faultsReported < 1}>
                <ReportedFaultsIcon
                  fill={
                    faultsReported > 0
                      ? theme.colors.copper
                      : theme.colors.disabled
                  }
                />
                {faultsReported}
              </FaultIcon>
              <FaultIcon empty={faultsRectified < 1}>
                <RectifiedFaultsIcon
                  fill={
                    faultsRectified > 0
                      ? theme.colors.darkGrey
                      : theme.colors.disabled
                  }
                />
                {faultsRectified}
              </FaultIcon>
            </FaultsIconWrapper>
          </ProgressItem>

        </ProgressItemWrapper>
        <TextButton label='Go to building page' onClick={handleRedirect} />
      </StatWrapper>
      {/* <RemoveBuildingModal */}
      {/*  open={open} */}
      {/*  building={building} */}
      {/*  onClose={handleRemoveModalClose} */}
      {/* /> */}
    </Wrapper>
  )
}

export default MyBuildingsListItem

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 0 4px rgba(101, 118, 184, 0.08),
  0 2px 4px rgba(101, 118, 184, 0.12), 0 8px 16px rgba(101, 118, 184, 0.06),
  0 1px 2px rgba(101, 118, 184, 0.06);
  border-radius: 6px;
  margin-bottom: 16px;
`

const DetailsWrapper = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const FrsName = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.trout};
`
const BuildingName = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 2px 0 4px;
`
const BuildingAddress = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.trout};
  height: 21px;
`
const StatWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ProgressItemWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ProgressItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.border};
  }

  &:first-of-type {
    padding-left: 0;
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.trout};
    text-transform: uppercase;
    white-space: nowrap;
  }
`
const FaultsText = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightTrout};
`
const FaultsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 15px;
`
const FaultIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ empty }) => (empty ? theme.colors.disabled : theme.colors.trout)};
`
