import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SM_LINKS } from 'utils/smLinks'
import fiaLogo from 'assets/fiaLogo.svg'
import ricsLogo from 'assets/ricsLogo.svg'
import cyberLogo from 'assets/cyberLogo.svg'

const Footer = () => {
  return (
    <Wrapper>
      <LogosWrapperHidden />
      <LinksWrapper>
        <SMLinks>
          <a
            href={SM_LINKS.linkedin.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <SMIcon src={SM_LINKS.linkedin.icon} />
          </a>
          <a
            href={SM_LINKS.twitter.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <SMIcon src={SM_LINKS.twitter.icon} />
          </a>
          <a
            href={SM_LINKS.instagram.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <SMIcon src={SM_LINKS.instagram.icon} />
          </a>
        </SMLinks>
        <ContentWrapper>
          <Link to='/privacy-policy'>Privacy Policy</Link>
          <Link to='/terms-and-conditions'>Terms & Conditions</Link>
          <Link to='/cookie-policy'>Cookie Policy</Link>
        </ContentWrapper>
      </LinksWrapper>
      <LogosWrapper>
        <img src={fiaLogo} />
        <img src={ricsLogo} />
        <img src={cyberLogo} />
      </LogosWrapper>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
  background-color: ${({ theme }) => theme.colors.haiti};
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SMLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 26px;
  width: 600px;

  a {
    cursor: pointer;
  }
`
const SMIcon = styled.img`
  &:hover {
    transform: scale(1.1);
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;

  > * {
    padding: 0 26px;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.lightCopper};
    }

    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.secondary};
    }
  }
`
const LogosWrapper = styled.div`
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  margin-right: 80px;
`
const LogosWrapperHidden = styled.div`
  visibility: hidden;
  width: 200px;
  margin-right: auto;
  margin-left: 80px;
`
