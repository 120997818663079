import Table from 'components/atoms/Table'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatDate } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import ReportedNoFaults from 'modules/buildingDetails/components/ReportedNoFaults'
import Status from 'components/atoms/Status'
import { find, pathOr, propEq, propOr } from 'ramda'
import { RectifiedFaultsIcon } from 'assets/icons'
import EyeIcon from 'assets/icons/EyeIcon'
import FaultPreviewModal from './FaultPreviewModal'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectFaultsDictionary } from 'redux/selectors/dictionarySelectors'
import {
  selectFaultsList,
  selectFaultsListPagination
} from 'redux/selectors/faultsSelectors'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { getFaults } from 'redux/slices/faultsSlice'
import qs from 'qs'

const RectifiedFaults = () => {
  const [openPreview, setOpenPreview] = useState(false)
  const [currentFault, setCurrentFault] = useState({})
  const faultsDictionary = useAppSelector(selectFaultsDictionary)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const pagination = useAppSelector(selectFaultsListPagination)

  const list = useAppSelector(selectFaultsList)

  useEffect(() => {
    const query = {
      limit: 20,
      page: 1,
      filter: { is_rectified: true }
    }
    dispatch(
      getFaults({ id, query: qs.stringify({ ...searchParams, ...query }) })
    )
  }, [search])

  const handleOpenPreview = (_, row, equipmentType) => {
    setOpenPreview(true)
    setCurrentFault({ ...row, equipmentType })
  }

  const handleClosePreview = () => {
    setOpenPreview(false)
  }

  const handlePageChange = (_, page) => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    dispatch(getFaults({ id, query: qs.stringify({ ...parsedQuery, page }) }))
    setSearchParams(qs.stringify({ ...parsedQuery, page }))
  }

  const headers = [
    { children: '' },
    { children: 'Id' },
    { children: 'Fault' },
    { children: 'Status' },
    { children: 'Reported' },
    { children: 'Rectified' },
    { children: '' }
  ]

  const rows = list.map(row => {
    const equipmentType = find(
      propEq('id', propOr('', 'equipmentTypeId', row)),
      faultsDictionary
    )
    return {
      cells: [
        {
          children: <RectifiedFaultsIcon />
        },
        {
          children: <IdWrapper>{propOr('-', 'serial', row)}</IdWrapper>
        },
        { children: <>{propOr('-', 'name', equipmentType)}</> },
        {
          children: (
            <Status
              status={pathOr(
                'rectified',
                ['rectification', 'status', 'value'],
                row
              )}
              withIcon
            />
          )
        },
        { children: <Date>{formatDate(propOr('-', 'createdAt', row))}</Date> },
        {
          children: (
            <Date>
              {formatDate(
                pathOr(null, ['rectification', 'rectificationDate'], row)
              )}
            </Date>
          )
        },
        {
          children: (
            <>
              <EyeIconWrapper
                onClick={e => handleOpenPreview(e, row, equipmentType)}
              />
              <FaultPreviewModal
                open={openPreview}
                onClose={handleClosePreview}
                fault={currentFault}
              />
            </>
          )
        }
      ]
    }
  })
  return (
    <>
      {isNotNilOrEmpty(list) ? (
        <Table
          headers={headers}
          rows={rows}
          pagination={pagination}
          onPageChange={handlePageChange}
        />
      ) : (
        <ReportedNoFaults />
      )}
    </>
  )
}

export default RectifiedFaults

const Date = styled.div`
  color: ${({ theme }) => theme.colors.lightTrout};
`

const IdWrapper = styled.div`
  color: ${({ theme }) => theme.colors.copper};
  display: flex;
  align-items: center;
  gap: 8px;
`
const EyeIconWrapper = styled(EyeIcon)`
  fill: ${({ theme }) => theme.colors.lightTrout};
  margin: 0 4px;
`
