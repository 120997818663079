import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'

type StatusPropsType = {
  status?: string
}

const ConfirmationPage = ({ status } : StatusPropsType)  => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => navigate(PATHS.login), 5000)
  }, [])

  return (
    <Wrapper>
      <Content>
        {status === 'success' ? (
          <>
            <Header>Your email address has been verified.</Header>
            <Description>
              You will be automatically redirected to login page.
            </Description>
          </>
        ) : (
          <>
            <Header>Your email address has not been verified.</Header>
            <Description>
              Please try clicking the link in the email we sent you once more.
              If that doesn&apos;t work you can contact the support team on{' '}
              <a href='mailto:app@buildingpassport.com'>
                app@buildingpassport.com
              </a>
            </Description>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

export default ConfirmationPage

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.dimensions.topNavHeight};
`

const Content = styled.div`
  max-width: 550px;
  font-size: 30px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.dimensions.topBarHeight};
`

const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`

const Description = styled.p`
  font-size: 20px;
`
