import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import { pathOr, propOr } from 'ramda'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setActiveForm, clearActiveForm } from 'redux/slices/formSlice'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import ProgressBar from 'components/atoms/ProgressBar'
import { CheckIcon, FeaturedIcon } from 'assets/icons'
import { getBuildingDetails } from 'redux/slices/buildingSlice'

type PropsTypes = {
  open: boolean
  onClose: () => void
  mode: string
}

const ThanksMessageModal = ({ open, onClose, mode = 'floorPlans' } : PropsTypes) => {
  const building = useAppSelector(selectCurrentBuilding)
  const frsName = pathOr('', ['frs', 'name'], building)
  const buildingName = pathOr('', ['name'], building)
  const floorsCompletionPercentage: number = propOr(
    '',
    'floorsCompletionPercentage',
    building
  )
  const isFloorPlanCompleted = floorsCompletionPercentage === 100
  const wallDesignCompletionPercentage: number = propOr(
    '',
    'wallDesignCompletionPercentage',
    building
  )
  const isWallDesignCompleted = wallDesignCompletionPercentage === 100

  const shouldDisplaySummaryButton =
    isFloorPlanCompleted && isWallDesignCompleted
  const isAllCompleted = isFloorPlanCompleted && isWallDesignCompleted
  const [activeSubmitForm, setActiveSubmitForm] = useState('plansBox')
  useEffect(() => {
    if (isFloorPlanCompleted && !isWallDesignCompleted) {
      setActiveSubmitForm('wallDesignBox')
    } else if (isWallDesignCompleted && !isFloorPlanCompleted) {
      setActiveSubmitForm('plansBox')
    }
  }, [isFloorPlanCompleted, isWallDesignCompleted])
  const dispatch = useAppDispatch()

  const handleClose = () => {
    onClose()
    dispatch(clearActiveForm())
    building && dispatch(getBuildingDetails({ id: building.id }))
  }

  const handleBackToSummary = () => {
    onClose()
    dispatch(clearActiveForm())
    building && dispatch(getBuildingDetails({ id: building.id }))
  }

  const handleFormModalPlansOpen = () => {
    dispatch(setActiveForm('plans'))
    onClose()
  }
  const handleFormModalWallDesignOpen = () => {
    dispatch(setActiveForm('wall_design'))
    onClose()
  }
  const handleFormModalFaultsOpen = () => {
    dispatch(setActiveForm('faults'))
    onClose()
  }

  const SubmitButtonFunction = mode => {
    switch (mode) {
      case 'buildingPlans':
        return handleFormModalPlansOpen()
      case 'floorPlans':
        return handleFormModalWallDesignOpen()
      case 'plansBox':
        return handleFormModalPlansOpen()
      case 'wallDesignBox':
        return handleFormModalWallDesignOpen()
      case 'wallDesign':
        return handleFormModalFaultsOpen()
      default:
        return handleFormModalPlansOpen()
    }
  }

  const SubmitButtonText = mode => {
    const modes = {
      buildingPlans: 'Add Building Plans',
      floorPlans: 'Add Wall Design',
      faults: 'Report Faults',
      plansBox: 'Add Building Plans',
      wallDesignBox: 'Add Wall Design'
    }
    return modes ? modes[mode] : 'Add Building Plans'
  }

  const ModalContent = mode => {
    const modes = {
      buildingPlans: (
        <>
          <Title>
            Thank you for submitting your building information to&nbsp;{frsName}
            .
          </Title>
          <Description>
            You have successfully added building information and Responsible
            Person details for <BoldText>{buildingName}</BoldText> to the
            information Exchange. This information is now saved in your
            Dashboard.
            <br />
            <br />
            What would you like to do next?
          </Description>
        </>
      ),
      floorPlans: (
        <>
          <Title>Thank you for submitting floor plans to&nbsp;{frsName}.</Title>
          <Description>
            You have successfully uploaded your floor plan file(s) and{' '}
            <BoldText>{frsName}</BoldText> now have secure access to them.
            <br />
            <br />
            {!isAllCompleted && 'What would you like to do next?'}
          </Description>
        </>
      ),
      wallDesign: (
        <>
          <Title>
            Thank you for submitting wall design information to&nbsp;{frsName}.
          </Title>
          <Description>
            You have successfully completed your external wall report and{' '}
            <BoldText>{frsName}</BoldText> now have secure access to it.
            <br />
            <br />
            {!isAllCompleted && 'What would you like to do next?'}
          </Description>
        </>
      )
    }
    return modes ? modes[mode] : 'Add Building Plans'
  }

  const handleSelect = value => () => {
    setActiveSubmitForm(value)
  }
  const textModalMode =
    (isFloorPlanCompleted && mode === 'floorPlans' && 'floorPlans') ||
    (isWallDesignCompleted && mode === 'wallDesign' && 'wallDesign') ||
    mode

  return (
    <>
      <Modal
        title={<FeaturedIcon />}
        open={open}
        onClose={handleClose}
        withSummary
        goTo={handleBackToSummary}
        withSubmit={!shouldDisplaySummaryButton}
        submitButtonText={SubmitButtonText(activeSubmitForm)}
        summaryButtonText='Go to Building Summary'
        onSubmit={() => SubmitButtonFunction(activeSubmitForm)}
      >
        {ModalContent(textModalMode)}
        <WrapperBox>
          <SubmitBox
            active={!isFloorPlanCompleted && activeSubmitForm === 'plansBox'}
            onClick={handleSelect('plansBox')}
            isDisabled={isFloorPlanCompleted}
          >
            <TopWrapper>
              <BoxTitle>Plans</BoxTitle>
              {isFloorPlanCompleted ? (
                <CheckIconWrapper>
                  <CheckIcon />
                </CheckIconWrapper>
              ) : (
                <RadioButtonWrapper active={activeSubmitForm === 'plansBox'}>
                  <Radio active={activeSubmitForm === 'plansBox'}>
                    <span className='dot' />
                  </Radio>
                </RadioButtonWrapper>
              )}
            </TopWrapper>
            <BoxDescription>
              Submit a record of floor and building plans
            </BoxDescription>
            <ProgressBar value={floorsCompletionPercentage} />
          </SubmitBox>
          <SubmitBox
            active={
              !isWallDesignCompleted && activeSubmitForm === 'wallDesignBox'
            }
            onClick={handleSelect('wallDesignBox')}
            isDisabled={isWallDesignCompleted}
          >
            <TopWrapper>
              <BoxTitle>Wall Design</BoxTitle>
              {isWallDesignCompleted ? (
                <CheckIconWrapper>
                  <CheckIcon />
                </CheckIconWrapper>
              ) : (
                <RadioButtonWrapper
                  active={activeSubmitForm === 'wallDesignBox'}
                >
                  <Radio active={activeSubmitForm === 'wallDesignBox'}>
                    <span className='dot' />
                  </Radio>
                </RadioButtonWrapper>
              )}
            </TopWrapper>
            <BoxDescription>
              Submit a record of the design and construction of external walls
            </BoxDescription>
            <ProgressBar value={wallDesignCompletionPercentage} />
          </SubmitBox>
        </WrapperBox>
        {isAllCompleted ? (
          <CompleteSection>
            <CompleteDescription>
              You have completed your information sharing requirements (unless
              your Fire & Rescue Service request anything further)
              <br />
              <br />
              Remember to complete your Faults Report if any fire safety
              equipment in the building is out of order.
            </CompleteDescription>
          </CompleteSection>
        ) : (
          <div />
        )}
      </Modal>
    </>
  )
}

export default ThanksMessageModal

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`

const Description = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`
const BoldText = styled.span`
  font-weight: bold;
`

const SubmitBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  border-radius: 4px;
  padding: 14px;
  border: 1px solid ${({ theme }) => theme.colors.background};
  cursor: ${({ isFrs }) => (isFrs ? 'default' : 'pointer')};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.copperBg};
    border-color: ${({ theme }) => theme.colors.copper} !important;
  }

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.colors.primary};
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`
const WrapperBox = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
`
const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  cursor: pointer;
`

const Radio = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.copper};
    border-radius: 50%;
    opacity: 0;
  }

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.colors.copper};

      .dot {
        opacity: 1;
      }
    `}
`
const BoxTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`
const BoxDescription = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text};
`
const CheckIconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.green};
`
const CompleteSection = styled.div`
  margin-top: 16px;
`
const CompleteDescription = styled.div``
