import React, { MouseEventHandler, useMemo } from 'react'
import styled from 'styled-components'
import {
  ExclamationIconOutlined,
  RectifiedFaultsIcon,
  ReportedFaultsIcon
} from 'assets/icons'
import { FormSeparator } from 'components/formComponents'
import Status from 'components/atoms/Status'
import { formatDate } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import { find, has, pathOr, propEq, propOr } from 'ramda'
import theme from 'utils/theme'
import FormModalWrapper from 'components/atoms/FormModalWrapper'
import BuildingDetailsInForm from 'components/atoms/BuildingDetailsInForm'
import { useAppSelector } from 'redux/hooks'
import { selectFaultsDictionary } from 'redux/selectors/dictionarySelectors'
import ResponsiblePerson from 'components/atoms/ResponsiblePerson'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'

type FaultPreviewModalTypes = {
  open: boolean
  fault: any
  onClose: MouseEventHandler
}

const FaultPreviewModal = ({
  open,
  fault,
  onClose
}: FaultPreviewModalTypes) => {
  const faultsDictionary = useAppSelector(selectFaultsDictionary)
  const {
    serial,
    equipmentTypeOptionValue,
    status,
    createdAt,
    rectification,
    reportedBy,
    evacuationStrategyChange,
    additionalMeasuresImplemented,
    additionalMeasuresDetails,
    equipmentType,
    equipmentTypeFreetext
  } = fault
  const building = useAppSelector(selectCurrentBuilding)
  const responsiblePersons = building?.responsiblePersons || []
  const reporter = has('email', reportedBy)
    ? responsiblePersons.find(person => person.email === reportedBy.email)
    : {}

  const rectifiedBy = propOr({}, 'rectifiedBy', rectification)
  const rectifiedReporter = has('email', rectifiedBy)
    ? responsiblePersons.find(person => person.email === rectifiedBy.email)
    : {}

  const isEvacuationStrategyChanged =
    propOr('', 'value', evacuationStrategyChange) === 'yes'

  const option = find(
    propEq('id', propOr('', 'id', equipmentType)),
    faultsDictionary
  )

  const equipmentTypeOption = useMemo(() => {
    const typeKey = propOr('', 'key', option)
    switch (typeKey) {
      case 'fire_detection_and_warning_system':
        return {
          title:
            'Is the fire detection and alarm system intended to provide warning to residents?',
          options: [
            {
              label: 'Yes',
              value: '1'
            },
            { label: 'No', value: '0' }
          ],
          description:
            'A fire detection system intended to warn residents would need to incorporate sounders. In residential buildings, fire detection systems will be used to initiate other protective measures (e.g. smoke control systems only) and may not include sounders (i.e. a silent alarm).'
        }
      case 'evacuation_lifts':
        return {
          title: 'Type of lift to which the fault relates',
          options: [
            {
              label: 'Lift for use by firefighters',
              value: 'firefighters'
            },
            { label: 'Evacuation lift', value: 'evacuation' }
          ]
        }
      case 'rising_mains':
        return {
          title: 'Type of rising mains to which the fault relates',
          options: [
            { label: 'Dry rising mains', value: 'dry' },
            { label: 'Wet rising mains', value: 'wet' }
          ]
        }
      case 'smoke_control_systems':
        return {
          title: 'Type of smoke control system to which the fault relates',
          options: [
            { label: 'Natural ventilation', value: 'natural' },
            { label: 'Mechanical extraction', value: 'mechanical' },
            { label: 'Positive pressure', value: 'pressure' }
          ]
        }
      case 'suppression_systems':
        return {
          title: 'Type of suppression system to which the fault relates',
          options: [
            { label: 'Sprinklers', value: 'sprinklers' },
            { label: 'Watermist', value: 'watermist' },
            { label: equipmentTypeFreetext, value: 'other' }
          ]
        }
      default:
        return {}
    }
  }, [option])

  const optionObject = equipmentTypeOption.options
    ? equipmentTypeOption.options.find(
        item => item.value === equipmentTypeOptionValue
      )
    : {}

  return open ? (
    <FormModalWrapper
      open={open}
      onClose={onClose}
      title={serial + ' Fault Report'}
    >
      <Wrapper>
        <Header>
          <HeaderTitle>
            <ReportedFaultsIconWrapper />
            <span>
              {serial + ' Fault - ' + propOr('-', 'name', equipmentType)}
            </span>
          </HeaderTitle>
          <StatusWrapper>
            {' '}
            <Status status={propOr('draft', 'value', status)} withIcon />
            {isNotNilOrEmpty(rectification) && (
              <Status
                status={pathOr('draft', ['status', 'value'], rectification)}
                withIcon
              />
            )}
          </StatusWrapper>
        </Header>
        <HeaderDate>reported: {formatDate(createdAt)}</HeaderDate>
        <FormSeparator />
        <BuildingDetailsInForm background={theme.colors.background} fullWidth />
        <FormSeparator />
        <Section>
          <SectionTitle>Type of equipment</SectionTitle>
          <div>
            <DetailsName>Type of equipment the fault relates to</DetailsName>
            <DetailsItem>{propOr('-', 'name', equipmentType)}</DetailsItem>
          </div>
          {isNotNilOrEmpty(equipmentTypeOptionValue) ? (
            <div>
              <DetailsName>
                {propOr('-', 'title', equipmentTypeOption)}
              </DetailsName>
              <DetailsItem> {propOr('-', 'label', optionObject)}</DetailsItem>
            </div>
          ) : null}
        </Section>
        <FormSeparator />
        <Section>
          <SectionTitle>Details of fault</SectionTitle>
          <div>
            <DetailsName>Nature of the fault</DetailsName>
            <DetailsItem>{propOr('-', 'details', fault)}</DetailsItem>
          </div>
          <div>
            <DetailsName>Location / area affected by the fault</DetailsName>
            <DetailsItem>{propOr('-', 'location', fault)}</DetailsItem>
          </div>
          <div>
            <DetailsName>Timescale for repair</DetailsName>
            <DetailsItem>{propOr('-', 'repairTimescale', fault)}</DetailsItem>
          </div>
        </Section>
        <FormSeparator />
        {isNotNilOrEmpty(rectification) && (
          <>
            <Section>
              <SectionTitle>Rectification</SectionTitle>
              <DetailCard>
                <DetailsName>
                  Additional information in relation to the rectification of the
                  fault
                </DetailsName>
                <DetailsItem>
                  {propOr('-', 'details', rectification)}
                </DetailsItem>
              </DetailCard>
            </Section>
            <FormSeparator />
          </>
        )}
        <Section>
          <SectionTitle>Fault mitigation</SectionTitle>
          <DetailCard
            bgColor={isEvacuationStrategyChanged && theme.colors.copperBg}
          >
            <DetailsName>
              <MitigationIconWrapper>
                <ExclamationIconOutlined
                  height='16'
                  width='16'
                  stroke={
                    isEvacuationStrategyChanged ? theme.colors.copper : ''
                  }
                />
              </MitigationIconWrapper>
              Fault{' '}
              {isEvacuationStrategyChanged
                ? 'necessitated'
                : `didn't necessitate`}{' '}
              a temporary change in evacuation strategy from stay put to
              simultaneous
            </DetailsName>
          </DetailCard>
          {isNotNilOrEmpty(rectification) &&
            rectification.evacuationStrategyReinstated && (
              <DetailCard bgColor={theme.colors.lightGreen}>
                <DetailsName>
                  <MitigationIconWrapper>
                    <RectifiedFaultsIcon
                      height='16'
                      width='16'
                      fill={theme.colors.green}
                    />
                  </MitigationIconWrapper>
                  The stay put evacuation policy has been reinstated after
                  rectification.
                </DetailsName>
              </DetailCard>
            )}
          {additionalMeasuresImplemented ? (
            <div>
              <DetailsName>
                Additional measures implemented to mitigate the fault reported
              </DetailsName>
              <DetailsItem>{additionalMeasuresDetails}</DetailsItem>
            </div>
          ) : null}
        </Section>
        <FormSeparator />
        <ResponsiblePersons>
          <PersonWrapper>
            <SectionTitle>Fault Reporter</SectionTitle>
            <ResponsiblePerson
              person={isNotNilOrEmpty(reporter) ? reporter : reportedBy}
            />
          </PersonWrapper>

          {isNotNilOrEmpty(rectifiedBy) && (
            <PersonWrapper>
              <SectionTitle>Rectification Reporter</SectionTitle>
              <ResponsiblePerson
                person={
                  isNotNilOrEmpty(rectifiedReporter)
                    ? rectifiedReporter
                    : propOr('', 'rectifiedBy', rectification)
                }
              />
            </PersonWrapper>
          )}
        </ResponsiblePersons>
      </Wrapper>
    </FormModalWrapper>
  ) : null
}

export default FaultPreviewModal

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 20px;
  padding: 20px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-weight: bold;
`
const HeaderDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightTrout};
  margin-top: 6px;
`
const ReportedFaultsIconWrapper = styled(ReportedFaultsIcon)`
  fill: ${({ theme }) => theme.colors.copper};
  height: 18px;
  width: 18px;
`
const ResponsiblePersons = styled.div`
  display: flex;
  gap: 20px;
`

const SectionTitle = styled.div`
  margin-bottom: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  color: #696d80;
`
const DetailsName = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 13px;
`
const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.trout};
  font-size: ${({ theme }) => theme.colors.trout};

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 13px;
  }
`
const PersonWrapper = styled.div`
  flex: 1;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 13px;
`
const DetailCard = styled.div`
  border-radius: 4px;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.background};
  padding: 8px;
`
const MitigationIconWrapper = styled.span`
  margin-right: 6px;
`
const StatusWrapper = styled.div`
  display: flex;
  gap: 6px;
`
