import React, { MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import theme from 'utils/theme'

type propsType = {
  label: string 
  onClick?: MouseEventHandler
  icon?: ReactNode
  rightIcon?: ReactNode
  color?: string
  fontSize?: string
  disabled?: boolean
}

const TextButton = ({ label, onClick, icon, rightIcon, color, fontSize, disabled }: propsType) => {
  return (
    <Button color={disabled ? theme.colors.disabled : color} fontSize={fontSize} onClick={onClick} disabled={disabled}>
      {icon && <IconWrapper color={disabled ? theme.colors.disabled : color}>{icon}</IconWrapper>}
      <div>{label}</div>
      {rightIcon && <IconWrapper color={color}>{rightIcon}</IconWrapper>}
    </Button>
  )
}

export default TextButton

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: ${({ theme, color }) => color || theme.colors.copper};
  transition: all .3s;
  
  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? 'transparent' : theme.colors.copperBg};
  }

  &:active {
    background-color: ${({ theme, disabled }) => disabled ? 'transparent' : theme.colors.copperBorder};
  }
`

const IconWrapper = styled.div`
  fill: ${({ theme, color }) => color || theme.colors.copper};
  display: flex;
  justify-content: center;

  svg {
    height: 14px;
    width: 14px;
  }
`
