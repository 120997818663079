import React, { useEffect } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { FormSeparator, SectionTitle } from 'components/formComponents'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, string } from 'yup'
import styled from 'styled-components'
import {
  setActiveStep,
  setFinishedStep,
  updateFormValues
} from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { dissoc, equals, pathOr, update } from 'ramda'
import ExternalWallSystemForm from 'modules/forms/wallsForm/ExternalWallSystemForm'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { debounce } from 'lodash'
import { addFinishedFormStep } from 'utils/forms'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import Button from 'components/atoms/Button'
import TextButton from 'components/atoms/TextButton'
import { PlusIcon } from 'assets/icons'

const schema = object({
  externalWallSystems: array()
    .of(
      object({
        externalFacingMaterials: array().min(1, 'This field is required'),
        isCombustible: string().required('This field is required'),
        combustibilityFurtherDetails: string()
          .when('isCombustible', {
            is: value => value === 'yes',
            then: schema =>
              schema
                .required('This field is required')
                .max(
                  250,
                  'Description should not be longer than 250 characters'
                ),
            otherwise: schema => schema.nullable().notRequired()
          })
          .nullable()
      })
    )
    .min(1, 'This field is required')
})

// helpers to manage multiple forms nested in one form
const getTempId = () =>
  String(Math.ceil(Math.random() * (9999999 - 9000000) + 9000000))
const isTempId = id => id.length === 7

const ExternalWallSystemsStep = () => {
  const dispatch = useAppDispatch()
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const existingWallSystems = currentBuilding.externalWallSystems
  const formValues = useAppSelector(selectActiveFormValues)
  const initialSystems = isNotNilOrEmpty(existingWallSystems)
    ? existingWallSystems.map(system => ({
      ...system,
      isCombustible: system.isCombustible.value,
      externalFacingMaterials: system.externalFacingMaterials.map(v => v.id)
    }))
    : [
      {
        id: getTempId(),
        externalFacingMaterials: [],
        isCombustible: undefined,
        combustibilityFurtherDetails: ''
      }
    ]

  const defaultValues = {
    externalWallSystems: initialSystems
  }

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    getValues
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    dispatch(updateFormValues(defaultValues))
  }, [])

  const values = getValues()

  const updateValues = debounce(v => {
    dispatch(updateFormValues(v))
  }, 500)

  useEffect(() => {
    const withoutTempIds = values.externalWallSystems?.map(system => {
      return isTempId(system.id) ? dissoc('id', system) : system
    })
    updateValues({
      ...values,
      externalWallSystems: withoutTempIds
    })
  }, [values.externalWallSystems])

  const externalWallSystems = watch('externalWallSystems')

  const onFormChange = index => values => {
    if (!equals(externalWallSystems[index], values)) {
      const updated = update(index, values, externalWallSystems)
      setValue('externalWallSystems', updated)
      trigger('externalWallSystems')
    }
  }

  const handleAddSystem = () => {
    setValue('externalWallSystems', [
      ...externalWallSystems,
      {
        externalFacingMaterials: [],
        isCombustible: undefined,
        combustibilityFurtherDetails: '',
        id: getTempId()
      }
    ])
  }

  const handleRemoveSystem = index => () => {
    setValue(
      'externalWallSystems',
      externalWallSystems.filter((_, idx) => idx !== index)
    )
  }

  const submit = () => {
    handleSubmit(data => {
      const withoutTempIds = data.externalWallSystems?.map(system => {
        return isTempId(system.id) ? dissoc('id', system) : system
      })
      dispatch(
        updateFormValues({
          externalWallSystems: withoutTempIds,
          buildingFormsState: addFinishedFormStep(
            'wall_design',
            'external_wall_system',
            formValues?.buildingFormsState || []
          )
        })
      )
      dispatch(setActiveStep('wall_features_and_attachments'))
      dispatch(setFinishedStep('external_wall_system'))
    })()
  }

  const handleSkip = () => {
    dispatch(
      updateFormValues({
        buildingFormsState: addFinishedFormStep(
          'wall_design',
          'external_wall_system',
          formValues?.buildingFormsState || []
        )
      })
    )
    dispatch(setActiveStep('wall_features_and_attachments'))
    dispatch(setFinishedStep('external_wall_system'))
  }

  const handleGoBack = () => {
    dispatch(setActiveStep('traditional_masonry_construction'))
  }

  return (
    <div>
      <ExpandableInfo
        title='Record details of the materials used in construction of the external walls '
        shortDescription={null}
      >
        <div>
          When reporting multiple external wall systems, record the details of
          the sections of walls that present the greatest risk of fire spread
          first, regardless of the total proportion of the building elevation
          they may represent.
        </div>
      </ExpandableInfo>
      {externalWallSystems &&
      externalWallSystems.map((formValues, index) => (
        <Form key={`wall-system-form-${formValues.id}`}>
          <SectionTitleWrapper>
            <SectionTitle>External wall system {index + 1}</SectionTitle>
            {externalWallSystems && externalWallSystems.length > 1 && (
              <RemoveIcon onClick={handleRemoveSystem(index)} />
            )}
          </SectionTitleWrapper>
          <FormWrapper>
            <ExternalWallSystemForm
              onChange={onFormChange(index)}
              initialValues={formValues}
              errors={pathOr({}, ['externalWallSystems', index], errors)}
            />
          </FormWrapper>
        </Form>
      ))}
      <TextButton
        label='add another external wall system'
        icon={<PlusIcon />}
        onClick={handleAddSystem}
      />
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={handleGoBack}>
          Back
        </Button>
        <NextButtonsWrapper>
          <SkipButton onClick={handleSkip}>Skip</SkipButton>
          <Button variant='outlined' onClick={submit}>
            Next step
          </Button>
        </NextButtonsWrapper>
      </ButtonsWrapper>
    </div>
  )
}

export default ExternalWallSystemsStep

const FormWrapper = styled.div`
  margin-top: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`

const Form = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`

const RemoveIcon = styled(DeleteOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

const SectionTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }
`

const NextButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const SkipButton = styled.div`
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
