import React from 'react'
import Icon from './Icon'

export const PhoneIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='13'
      height='13'
      viewBox='0 0 13 13'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.9997 12.6667C12.551 12.6667 12.9997 12.218 12.9997 11.6667V9.33333C12.9997 8.782 12.551 8.33333 11.9997 8.33333C11.2197 8.33333 10.453 8.21067 9.71968 7.96867C9.54438 7.91331 9.35735 7.90659 9.17853 7.94921C8.99971 7.99183 8.83582 8.08219 8.70434 8.21067L7.74434 9.17067C6.08508 8.26988 4.72236 6.90762 3.82101 5.24867L4.77301 4.29067C5.04767 4.036 5.14634 3.64867 5.02901 3.276C4.7875 2.54153 4.66506 1.77315 4.66634 1C4.66634 0.448667 4.21767 0 3.66634 0H1.33301C0.781674 0 0.333008 0.448667 0.333008 1C0.333008 7.43333 5.56634 12.6667 11.9997 12.6667ZM1.33301 0.666667H3.66634C3.75475 0.666667 3.83953 0.701786 3.90204 0.764298C3.96456 0.82681 3.99967 0.911595 3.99967 1C3.99967 1.85133 4.13301 2.68733 4.39501 3.48C4.41426 3.53752 4.41651 3.59937 4.40149 3.65814C4.38647 3.71691 4.35483 3.7701 4.31034 3.81133L3.00634 5.122C4.09767 7.274 5.70967 8.886 7.87167 9.98667L9.17101 8.68667C9.21534 8.64279 9.27078 8.6118 9.33137 8.597C9.39196 8.58221 9.45544 8.58417 9.51501 8.60267C10.3123 8.86667 11.1483 9 11.9997 9C12.183 9 12.333 9.15 12.333 9.33333V11.6667C12.333 11.85 12.183 12 11.9997 12C5.93434 12 0.999674 7.06533 0.999674 1C0.999674 0.911595 1.03479 0.82681 1.09731 0.764298C1.15982 0.701786 1.2446 0.666667 1.33301 0.666667Z' />
    </Icon>
  )
}

export default PhoneIcon
