import React, { ReactNode, useState } from 'react'
// import { FieldValues, UseFormRegister } from 'react-hook-form'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'

type InputPropTypes = {
  error?: string
  label?: string
  placeholder?: string
  register: UseFormRegister<FieldValues>
  name: string
  type?: string
  additionalLabel?: ReactNode
  multiline?: boolean
  leftIcon?: ReactNode
  value?: string
  disabled?: boolean
  hasCounter?: boolean
  numberOfCharacters?: number
  maxCharacters?: number
}

const Input = (props: InputPropTypes) => {
  const {
    error,
    label,
    register,
    name,
    additionalLabel,
    multiline,
    leftIcon,
    value,
    hasCounter,
    maxCharacters,
  } = props

  const [numberOfCharacter, setNumberOfCharacter] = useState<number>(0)
  const isTooManyCharacter = maxCharacters && numberOfCharacter > maxCharacters
  const hasLeftIcon = isNotNilOrEmpty(leftIcon)

  return (
    <Wrapper>
      {label && (
        <LabelWrapper>
          <Label>{label}</Label>
          {additionalLabel && additionalLabel}
        </LabelWrapper>
      )}
      {multiline ? (
        <StyledTextarea
          {...register(name)}
          {...props}
          rows={4}
          onChange={e => {
            const { onChange } = register(name)
            const value = e.target?.value || ''
            onChange(e)
            setNumberOfCharacter(value.length)
          }}
        />
      ) : (
        <>
          <StyledInput
            hasLeftIcon={hasLeftIcon}
            {...register(name)}
            {...props}
            value={value}
          />
          {leftIcon && <LeftIconWrapper hasLabel={isNotNilOrEmpty(label)}>{leftIcon}</LeftIconWrapper>}
        </>
      )}
      {hasCounter && (
        <CounterCharacterWrapper
          isTooManyCharacter={isTooManyCharacter}
        >{`${numberOfCharacter}/${maxCharacters}`}</CounterCharacterWrapper>
      )}
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default Input

const Wrapper = styled.div`
  margin-bottom: 15px;
  position: relative;
`

const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  outline: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: ${({ hasLeftIcon }) => (hasLeftIcon ? '10px 10px 10px 45px' : '10px 14px')};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  outline: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  resize: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
const LeftIconWrapper = styled.div`
  position: absolute;
  top: ${({ hasLabel }) => hasLabel ? '35px' : '13px'};
  left: 16px;
  height: 100%;
`

const CounterCharacterWrapper = styled.div`
  margin-top: 5px;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme, isTooManyCharacter }) =>
    isTooManyCharacter ? theme.colors.error : theme.colors.trout};
  font-weight: ${({ isTooManyCharacter }) =>
    isTooManyCharacter ? 'bold' : 400};
`
