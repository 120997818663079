import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { signUp } from 'redux/slices/authSlice'
import { Link, useNavigate } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { FieldValues, useForm } from 'react-hook-form'
import Input from 'components/atoms/Input'
import { boolean, string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { selectAuthLoading } from 'redux/selectors/authSelectors'
import PasswordInput, {
  passwordRegex
} from 'components/atoms/password/PasswordInput'
import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'

const schema = {
  type: string().oneOf(['user', 'emergency_service'], 'Incorrect type'),
  email: string().required('Email is required').email('Incorrect Email'),
  password: string()
    .required('Password is required')
    .matches(passwordRegex, 'Incorrect Password'),
  disclaimer: boolean().oneOf([true], 'Required'),
  updates: boolean()
}

const RegisterUser = () => {
  const isLoading = useAppSelector(selectAuthLoading)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const defaultValues : FieldValues = {
    email: '',
    password: '',
    disclaimer: false,
    updates: false
  }

  const label = (
    <>
      I agree to<Link to={PATHS.privacy}>Building Passport Policy</Link>&
      <Link to={PATHS.terms}>Terms of use</Link>
    </>
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(object().shape(schema))
  })

  const handleRegisterUser = async data => {
    const registerData = {
      ...data,
      type: 'user',
      source: 'info_exchange'
    }
    const result = await dispatch(signUp(registerData))
    if (result.payload) {
      navigate(PATHS.registerConfirm)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleRegisterUser)}>
      <Input
        register={register}
        name='email'
        error={errors.email?.message as string}
        label='Email'
      />
      <PasswordInput
        register={register}
        error={errors.password?.message as string}
        name='password'
        label='Password'
        inputValue={watch('password')}
        showRequirements
      />
      <CheckboxWrapper>
        <Checkbox
          id='disclaimer'
          label={label}
          error={errors.disclaimer?.message as string}
          inputProps={register('disclaimer')}
        />
        <Checkbox
          id='updates'
          label='I want to be kept up to date via occasional emails highlighting changes to legislation, new features and special offers'
          error={errors.updates?.message as string}
          inputProps={register('updates')}
        />
      </CheckboxWrapper>
      <ButtonWrapper>
        <Button isLoading={isLoading} disabled={isLoading} type='submit'>
          Sign up
        </Button>
        <ButtonSeparator>
          <span>Already have Building Passport account?</span>
        </ButtonSeparator>
        <Button variant='outlined' onClick={() => navigate('/login')}>
          Log in
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default RegisterUser

const CheckboxWrapper = styled.div`
  margin-top: 30px;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
  }
`
const ButtonSeparator = styled.div`
  display: flex;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.trout};
  padding: 20px 0;

  span {
    padding: 0 6px;
  }

  :before,
  :after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    margin: auto;
  }
`
