import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PATHS } from 'utils/paths'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import DecorativeButton from 'components/atoms/DecorativeButton'
import { useNavigate, useParams } from 'react-router-dom'
import { getFrsSlug } from 'services/DictionaryService'
import { getApiErrors } from 'utils/errors'
import { pathOr } from 'ramda'

const Invitation = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const [frsInfo, setFrsInfo] = useState(null)

  useEffect(() => {
    if (isNotNilOrEmpty(slug)) {
      getFrsSlug(slug)
        .then(resp => {
          const frsDetails = resp.data.data
          setFrsInfo(frsDetails)
        })
        .catch(error => {
          console.error(getApiErrors(error))
        })
    }
  }, [slug])

  const frsProvided = isNotNilOrEmpty(frsInfo)
  const domain = pathOr('', ['domain'], frsInfo)

  return (
    <Wrapper>
      <HeaderWrapper>
        <ContentWrapper>
          <Header>
            Securely transfer your building information to the Fire Service.
          </Header>
          {(!frsProvided || isNilOrEmpty(domain)) && (
            <InvitationInfo>
              You have been invited to share building information{' '}
            </InvitationInfo>
          )}
          {frsProvided && isNotNilOrEmpty(domain) && (
            <InvitationInfo>
              You have been invited by{' '}
              <Link
                href={`http://www.${domain}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {pathOr('', ['name'], frsInfo)}
              </Link>{' '}
              to share building information{' '}
            </InvitationInfo>
          )}
          <div>
            in accordance with the{' '}
            <Link
              href='https://www.gov.uk/government/publications/fire-safety-england-regulations-2022'
              target='_blank'
              rel='noopener noreferrer'
            >
              Fire Safety (England) Regulations.
            </Link>
          </div>
          <ButtonWrapper>
            <DecorativeButton onClick={() => navigate(PATHS.login)}>
              Share information with FRS
            </DecorativeButton>
          </ButtonWrapper>
        </ContentWrapper>
      </HeaderWrapper>
    </Wrapper>
  )
}

export default Invitation

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent 60%, #eaeef6aa);
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 630px;
  width: 630px;
  margin-left: 72px;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
`
const Header = styled.div`
  font-size: 50px;
  margin-bottom: 24px;
`
const InvitationInfo = styled.h2`
  font-size: 18px;
`
const Link = styled.a`
  color: ${({ theme }) => theme.colors.copper};
  font-weight: bolder;

  &:hover {
    color: ${({ theme }) => theme.colors.lightCopper};
  }
`
const ButtonWrapper = styled.div`
  width: 250px;
  margin-top: 24px;
`
