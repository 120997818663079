import React from 'react'
import theme from 'utils/theme'
import Icon from './Icon'

export const ExclamationIconOutlined = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      {...props}
    >
      <path
        d='M10.0003 6.6665V9.99984M10.0003 13.3332H10.0087M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z'
        stroke={props.stroke || theme.colors.lightTrout}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  )
}

export default ExclamationIconOutlined
