import React from 'react'
import Icon from './Icon'

export const JoinIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='20'
      height='22'
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 16.0001C4 16.3514 4 16.527 4.01567 16.6797C4.14575 17.9475 5.0626 18.9947 6.30206 19.2912C6.45134 19.3269 6.6255 19.3501 6.97368 19.3966L13.5656 20.2755C15.442 20.5257 16.3803 20.6508 17.1084 20.3612C17.7478 20.107 18.2803 19.6408 18.6168 19.0407C19 18.3572 19 17.4106 19 15.5176V6.48256C19 4.58952 19 3.643 18.6168 2.95947C18.2803 2.35936 17.7478 1.89313 17.1084 1.63892C16.3803 1.34938 15.442 1.47448 13.5656 1.72467L6.97368 2.60359C6.62546 2.65002 6.45135 2.67324 6.30206 2.70895C5.0626 3.00544 4.14575 4.05266 4.01567 5.32043C4 5.47313 4 5.64878 4 6.00008M10 7.00008L14 11.0001M14 11.0001L10 15.0001M14 11.0001H1'
        stroke='#D14925'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  )
}

export default JoinIcon
