import React, { useEffect, useMemo, useState } from 'react'
import FiltersSection from 'modules/myBuildings/components/filters/FiltersSection'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingFilters } from 'redux/selectors/dictionarySelectors'
import { keys, equals, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { snakeCase } from 'lodash'
import styled from 'styled-components'
import { LevelBarsIcon } from 'assets/icons'
import Checkbox from 'components/atoms/Checkbox'

const FireSpreadRiskLevelFilter = ({ onChange, counter, queryFilters }) => {
  const buildingFilters = useAppSelector(selectBuildingFilters)
  const filters = pathOr({}, ['fireSpreadRiskLevel'], buildingFilters)
  const [values, setValues] = useState({})
  const [lastChosen, setLastChosen] = useState({})

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      const query = keys(values)
        .map(key => (values[key] ? snakeCase(key) : null))
        .filter(v => isNotNilOrEmpty(v))
        .join(',')
      onChange('fire_spread_risk_level', query)
    }
  }, [values])

  const handleChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!equals(queryFilters, lastChosen)) {
      const newValues = {}
      for (const key in values) {
        newValues[snakeCase(key)] = queryFilters.includes(snakeCase(key))
      }
      setValues(newValues)
      setLastChosen(queryFilters)
    }
  }, [queryFilters])

  useEffect(() => {
    const newFilters = {}
    if (isNotNilOrEmpty(queryFilters)) {
      queryFilters.forEach(v => {
        newFilters[v] = true
      })
      setValues(prev => ({ ...prev, ...newFilters }))
    }
  }, [])

  const getLabel = (key, counter) => {
    switch (key) {
      case 'high':
        return (
          <Option>
            <OptionTitle>
              <LevelBarsIcon level='high' /> High risk ({counter})
            </OptionTitle>
            <OptionDescription>
              Significantly increased risk of external fire spread and
              remediation works are necessary
            </OptionDescription>
          </Option>
        )
      case 'medium':
        return (
          <Option>
            <OptionTitle>
              <LevelBarsIcon level='medium' /> Medium risk ({counter})
            </OptionTitle>
            <OptionDescription>
              Increased risk of external fire spread, remediation may not be
              necessary
            </OptionDescription>
          </Option>
        )
      case 'low':
        return (
          <Option>
            <OptionTitle>
              <LevelBarsIcon level='low' />
              Low risk ({counter})
            </OptionTitle>
            <OptionDescription>
              No increased risk of external fire spread, no remediation
              necessary
            </OptionDescription>
          </Option>
        )
      default:
        return (
          <Option>
            <OptionTitle>
              <LevelBarsIcon /> Not determined ({counter})
            </OptionTitle>
            <OptionDescription>
              The overall level of risk of the external walls has not been
              determined
            </OptionDescription>
          </Option>
        )
    }
  }

  const content = useMemo(() => {
    if (isNotNilOrEmpty(filters)) {
      return keys(filters).map(key => {
        return (
          <Checkbox
            id={`fire_spread_risk_level-${key}`}
            key={`fire_spread_risk_level-${key}`}
            label={getLabel(key, filters[key])}
            inputProps={{
              disabled: filters[key] === 0,
              onChange: e => handleChange(snakeCase(key), e.target.checked),
              checked: values[snakeCase(key)]
            }}
          />
        )
      })
    } else {
      return null
    }
  }, [filters, queryFilters, values])

  return (
    <FiltersSection title='Overall fire spread risk' counter={counter}>
      {content}
    </FiltersSection>
  )
}

export default FireSpreadRiskLevelFilter

const Option = styled.div`
  margin-top: -1px;
`

const OptionTitle = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`

const OptionDescription = styled.div`
  font-size: 12px;
`
