import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { head } from 'ramda'
import { convertBytes } from 'utils/files'
import { CircularProgress } from '@mui/material'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import { isNotNilOrEmpty } from 'utils/ramda'

type FileInputTypes = {
  onChange: any
  isUploaded: boolean
  isLoading: boolean
  accept?: string
}

const FileInput = ({
  onChange,
  isUploaded,
  isLoading,
  accept = '*'
}: FileInputTypes) => {
  // eslint-disable-next-line no-undef
  const [file, setFile] = useState<File | {}>({})

  const onDrop = useCallback(acceptedFiles => {
    onChange(head(acceptedFiles))
    const firstFile = head(acceptedFiles)
    setFile(firstFile)
  }, [])

  const options = { onDrop }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    // @ts-ignore
    isNotNilOrEmpty(accept) ? { ...options, accept } : options
  )

  return (
    <FileInputWrapper {...getRootProps()}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <input {...getInputProps()} accept={accept} />
          {isUploaded ? (
            <UploadedFileWrapper>
              <FileIcon />
              <DetailsWrapper>
                <FileName>
                  {/* @ts-ignore */}
                  {file.name}
                </FileName>
                <FileSize>
                  {/* @ts-ignore */}
                  {convertBytes(file.size)}
                </FileSize>
              </DetailsWrapper>
            </UploadedFileWrapper>
          ) : (
            <EmptyStateWrapper>
              {isDragActive ? (
                <p>Drop file here...</p>
              ) : (
                <InputDescription>
                  <UploadIcon />
                  <div>
                    <span>Click to upload</span> or drag and drop file
                    <p>({accept.replace(/,/g, ', ').toUpperCase()})</p>
                  </div>
                </InputDescription>
              )}
            </EmptyStateWrapper>
          )}
        </>
      )}
    </FileInputWrapper>
  )
}

export default FileInput

const FileInputWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border: 1px dashed ${({ theme }) => theme.colors.copper};
  border-radius: 8px;
  width: 100%;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.trout};
  background-color: #fff;
  margin-bottom: 20px;
  cursor: pointer;

  .MuiCircularProgress-colorPrimary {
    color: red;
  }

  &:focus {
    outline: none;
  }
`

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    margin-left: 10px;
    font-size: 12px;
    text-align: center;
  }
`

const UploadedFileWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`

const FileName = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  margin-left: 15px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.trout};
  font-weight: bold;
`

const FileSize = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.trout};
  margin-left: 15px;
`

const DetailsWrapper = styled.div`
  box-sizing: border-box;
  max-width: 85%;
`

const InputDescription = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`

const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  font-size: 50px !important;
  color: ${({ theme }) => theme.colors.primary};
`

const UploadIcon = styled(UploadFileOutlinedIcon)`
  font-size: 40px !important;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`
