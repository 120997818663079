import React from 'react'
import Icon from './Icon'

export const ArrowLeftIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='10'
      height='10'
      viewBox='0 0 10 10'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.66679 5.16667H1.50012L5.00012 8.66667L4.55745 9.16667L0.224121 4.83333L4.55745 0.5L5.00012 1L1.50012 4.5H9.66679V5.16667Z' />
    </Icon>
  )
}

export default ArrowLeftIcon
