import React, { useEffect, useMemo } from 'react'
import Select from 'components/atoms/Select'
import { FormSeparator, InputDescription } from 'components/formComponents'
import TrueFalseSelect from 'modules/forms/wallsForm/components/TrueFalseSelect'
import Input from 'components/atoms/Input'
import { FieldValues, useForm } from 'react-hook-form'
import { useAppSelector } from 'redux/hooks'
import { selectExternalFacingMaterials } from 'redux/selectors/dictionarySelectors'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, string } from 'yup'
import { debounce } from 'lodash'
import { pathOr } from 'ramda'

const schema = object({
  externalFacingMaterials: array().min(1, 'This field is required'),
  isCombustible: string().required('This field is required'),
  combustibilityFurtherDetails: string()
    .when('isCombustible', {
      is: value => value === 'yes',
      then: schema =>
        schema
          .required('This field is required')
          .max(250, 'Description should not be longer than 250 characters'),
      otherwise: schema => schema.nullable().notRequired()
    })
    .nullable()
})

interface errorsTypes {
  externalWallSystems?: {
    externalFacingMaterials?: {
      type?: string
      message?: string
    }
    isCombustible?: {
      type?: string
      message?: string
    }
  }[]
}

type PropsTypes = {
  initialValues: FieldValues
  onChange: (values: FieldValues) => void
  errors?: errorsTypes
}

const ExternalWallSystemForm = ({
  initialValues,
  onChange,
  errors
}: PropsTypes) => {
  const { watch, setValue, control, register } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  })

  const externalFacingMaterials = useAppSelector(selectExternalFacingMaterials)

  const values = watch()
  const selectedMaterials = watch('externalFacingMaterials')

  const debouncedOnChange = debounce(() => onChange(values), 400)

  useEffect(() => {
    debouncedOnChange()
  }, [values])

  const isCombustible = watch('isCombustible')

  const materialOptions = useMemo(() => {
    return externalFacingMaterials.map(material => ({
      label: material.name,
      value: material.id
    }))
  }, [externalFacingMaterials])

  const handleMaterialDeselect = value => {
    const filteredValue = selectedMaterials.filter(
      material => material !== value
    )
    setValue('externalFacingMaterials', filteredValue)
  }

  return (
    <div>
      <Select
        label='Select external facing materials which are present over the outer masonry layer'
        control={control}
        noMarginBottom
        multiple
        name='externalFacingMaterials'
        options={materialOptions}
        onItemDeselect={handleMaterialDeselect}
        error={pathOr(' ', ['externalFacingMaterials', 'message'], errors)}
      />
      <FormSeparator />
      <TrueFalseSelect
        control={control}
        name='isCombustible'
        isColumn
        addNotSureOption
        initialValue={isCombustible}
        title='Are these walls likely to ignite and spread fire easily?'
        description='Consideration should be given to the combustibility of the external facing material (above), combustibility of any insulation, and any confirmed issues with the construction methods.'
        onChange={value => setValue('isCombustible', value)}
        error={pathOr('', ['isCombustible', 'message'], errors)}
      />
      {isCombustible === 'yes' && (
        <>
          <Input
            name='combustibilityFurtherDetails'
            multiline
            register={register}
            label='Provide further details'
            error={pathOr(
              '',
              ['combustibilityFurtherDetails', 'message'],
              errors
            )}
            placeholder='Enter a description...'
          />
          <InputDescription>
            Include the reason why the wall will contribute to fire spread (e.g.
            what materials are combustible), and where appropriate, how these
            sections of wall can be distinguished from those which will not
            promote fire spread.
          </InputDescription>
        </>
      )}
    </div>
  )
}

export default ExternalWallSystemForm
