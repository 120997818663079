import React from 'react'
import { FormControlLabel, RadioGroup, Radio as RadioMui } from '@mui/material'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

type RadioTypes = {
  name: string
  options: any
  control: any
}

const Radio = ({ name, options, control }: RadioTypes) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledGroup {...field}>
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<RadioMui size='small' />}
              label={
                <div>
                  <OptionLabel>{option.label}</OptionLabel>
                  {option.description && (
                    <OptionDescription>{option.description}</OptionDescription>
                  )}
                  {option.activeDescription && option.isActive && (
                    <OptionDescription>
                      {option.activeDescription}
                    </OptionDescription>
                  )}
                </div>
              }
            />
          ))}
        </StyledGroup>
      )}
    />
  )
}

export default Radio

const StyledGroup = styled(RadioGroup)`
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: 'FuturaPT-Book', 'Open Sans', sans-serif;
    font-weight: 400;
    color: #000;
  }

  .MuiRadio-colorPrimary {
    color: ${({ theme }) => theme.colors.trout};
  }

  .Mui-checked {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`

const OptionLabel = styled.div`
  font-size: 14px;
`

const OptionDescription = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.trout};
`
