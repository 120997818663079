import numeral from 'numeral'

export const getFloorOptions = (values, short = false) => {
  const floors: any = []

  if (values) {
    if (values.undergroundFloorsNumber > 0) {
      for (let i = 1; i <= values.undergroundFloorsNumber; i++) {
        floors.push({ label: short ? `${-i}` : `Basement level ${i}`, value: `${-i}` })
      }
    }

    floors.reverse()

    if (values.lowerGroundFloor) {
      floors.push({ label: short ? 'LG' : 'Lower Ground Floor', value: `LG` })
    }

    if (values.groundFloor) {
      floors.push({ label: short ? 'G' : 'Ground Floor', value: `G` })
    }

    if (values.floorsNumber > 0) {
      for (let i = 1; i <= values.floorsNumber; i++) {
        floors.push({ label: short ? i : `${numeral(i).format('oO')} floor`, value: `${i}` })
      }
    }
  }
  return floors
}

export const getFloorsIntervals = floors => {
  const sorted = floors ? floors.sort((a, b) => Number(a) - Number(b)) : []

  const intervals: any = []

  const groundIndex = sorted.indexOf(0)
  const lowerGroundIndex = sorted.indexOf(-0.5)

  if (groundIndex > -1) {
    sorted.splice(groundIndex, 1)
    intervals.push('G')
  }

  if (lowerGroundIndex > -1) {
    sorted.splice(lowerGroundIndex, 1)
    intervals.push('LG')
  }

  let currentIndex = intervals.length > 0 ? intervals.length : 0

  sorted.forEach((floor, index) => {
    if (sorted[index - 1]) {
      Math.abs(floor - sorted[index - 1]) !== 1 && currentIndex++
      const currentInterval = intervals[currentIndex] || []
      intervals[currentIndex] = [
        ...currentInterval,
        floor
      ]
    } else {
      const currentInterval = intervals[currentIndex] || []
      intervals[currentIndex] = [
        ...currentInterval,
        floor
      ]
    }
  })

  return intervals.map(el => {
    return typeof el === 'object'
      ? el.length > 1
        ? `${Math.min(...el)} to ${Math.max(...el)}`
        : el[0]
      : el
  })
}
