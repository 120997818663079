import React from 'react'
import Icon from './Icon'

export const FiltersIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.6663 6.66667H14.1247C13.9163 5.70833 13.083 5 12.083 5C11.083 5 10.2497 5.70833 10.0413 6.66667H3.33301V7.5H10.0413C10.2497 8.45833 11.083 9.16667 12.083 9.16667C13.083 9.16667 13.9163 8.45833 14.1247 7.5H16.6663V6.66667ZM12.083 8.33333C11.3747 8.33333 10.833 7.79167 10.833 7.08333C10.833 6.375 11.3747 5.83333 12.083 5.83333C12.7913 5.83333 13.333 6.375 13.333 7.08333C13.333 7.79167 12.7913 8.33333 12.083 8.33333Z'
        fill='#696D80'
      />
      <path
        d='M3.33301 13.3334H5.87467C6.08301 14.2917 6.91634 15 7.91634 15C8.91634 15 9.74967 14.2917 9.95801 13.3334H16.6663V12.5H9.95801C9.74967 11.5417 8.91634 10.8334 7.91634 10.8334C6.91634 10.8334 6.08301 11.5417 5.87467 12.5H3.33301V13.3334ZM7.91634 11.6667C8.62467 11.6667 9.16634 12.2084 9.16634 12.9167C9.16634 13.625 8.62467 14.1667 7.91634 14.1667C7.20801 14.1667 6.66634 13.625 6.66634 12.9167C6.66634 12.2084 7.20801 11.6667 7.91634 11.6667Z'
        fill='#696D80'
      />
    </Icon>
  )
}

export default FiltersIcon
