import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FieldValues, useForm } from 'react-hook-form'
import Input from 'components/atoms/Input'
import { FormSeparator } from 'components/formComponents'
import Button from 'components/atoms/Button'
import { equals } from 'ramda'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { StepProps } from 'utils/types'

const schema = object({
  details: string()
    .nullable()
    .required('This field is required'),
  location: string()
    .nullable()
    .required('This field is required'),
  repairTimescale: string()
    .nullable()
    .required('This field is required')
})
interface DetailsOfFaultStepProps extends StepProps {
  onGoBack: () => void
}

const DetailsOfFaultStep = ({ onStepFinish, onGoBack, onValuesChange, initialValues }: DetailsOfFaultStepProps) => {
  const [lastValues, setLastValues] = useState({})

  const defaultValues : FieldValues = {
    details: initialValues?.details || '',
    location: initialValues?.location || '',
    repairTimescale: initialValues?.repairTimescale || ''
  }

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const submit = () => {
    handleSubmit(() => {
      onStepFinish()
    })()
  }

  const values = watch()

  useEffect(() => {
    if (!equals(lastValues, values)) {
      onValuesChange(values)
      setLastValues(values)
    }
  }, [values])

  return (
    <div>
      <Section>
        <Title>What is the <span>nature of the fault</span>?</Title>
        <Description>Provide an overview of the fault and how it affects operation of the fire safety equipment. The
          fire and rescue service will need to understand whether the equipment not working exactly like it should or is
          not working at all.</Description>
        <Input
          register={register}
          multiline
          placeholder='Enter a description...'
          name='details'
          label='Provide further detail'
          error={errors.details?.message as string}
        />
      </Section>
      <Section>
        <Title>What is the <span>location of the fault</span> and / or <span>area affected by the fault</span>?</Title>
        <Description>Does the fault have an impact on the entire building or does it only affect certain areas (e.g.
          specific floors) within the building?</Description>
        <Input
          register={register}
          multiline
          placeholder='Enter a description...'
          name='location'
          label='Provide further detail'
          error={errors.location?.message as string}
        />
      </Section>
      <Section>
        <Title>What is the estimated <span>timescale for repair</span>?</Title>
        <Description>Whilst it is acknowledged that timescales for repair may not be known until an engineer has visited
          site and further investigation has taken place, at this stage is it considered likely the fault will be
          addressed quickly, or will significant remedial works be required?</Description>
        <Input
          register={register}
          multiline
          placeholder='Enter a description...'
          name='repairTimescale'
          label='Provide further detail'
          error={errors.repairTimescale?.message as string}
        />
      </Section>
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={onGoBack}>Back</Button>
        <Button variant='outlined' onClick={submit}>Next step</Button>
      </ButtonsWrapper>
    </div>
  )
}

export default DetailsOfFaultStep

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Description = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`

const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: 150px !important;
  }
`
