import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { FieldValues, useForm } from 'react-hook-form'
import { string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import qs from 'qs'
import styled from 'styled-components'
import { selectAuthLoading } from 'redux/selectors/authSelectors'
import PasswordInput, {
  passwordRegex
} from 'components/atoms/password/PasswordInput'
import toast from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { PATHS } from 'utils/paths'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from 'services/AuthService'
import AuthFormWrapper from 'modules/auth/AuthFormWrapper'
import Button from 'components/atoms/Button'

const schema = object({
  password: string()
    .required('Password is required')
    .matches(passwordRegex, 'Incorrect password'),
  password_confirmation: string().test(
    'password-match',
    'Passwords do not match',
    function (value) {
      const { password } = this.parent
      return password === value
    }
  )
})

const PasswordReset = () => {
  const isLoading = useAppSelector(selectAuthLoading)
  const defaultValues: FieldValues = {
    password: '',
    password_confirmation: ''
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()

  const handleResetPassword = async data => {
    const queryParams = qs.parse(window.location.search.substring(1))
    await resetPassword({ ...queryParams, ...data })
      .then(res => {
        toast.success('Your password has been changed.')
        setTimeout(() => navigate(PATHS.login), 2000)
      })
      .catch(e => toast.error(getApiErrors(e)))
  }

  return (
    <AuthFormWrapper>
      {' '}
      <FormWrapper>
        <HeaderText>
          Change your&nbsp;
          <span>Password</span>
        </HeaderText>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <PasswordInput
            register={register}
            error={errors.password?.message as string}
            name='password'
            label='New Password'
            inputValue={watch('password')}
            showRequirements
          />
          <PasswordInput
            register={register}
            error={errors.password_confirmation?.message as string}
            name='password_confirmation'
            label='Confirm New Password'
          />
          <ButtonWrapper>
            <Button isLoading={isLoading} disabled={isLoading} type='submit'>
              Change Password
            </Button>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </AuthFormWrapper>
  )
}

export default PasswordReset

const FormWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 35px 70px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.dimensions.topNavHeight};
`

const ButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
  }
`

const HeaderText = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;

  span {
    font-weight: bold;
  }
`
