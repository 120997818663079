import { array, boolean, number, object, string } from 'yup'
import { isNotNilOrEmpty } from 'utils/ramda'
import { store } from 'redux/store'
import { has, pathOr } from 'ramda'
import { validateEmail } from 'utils/forms'

const getFormSchema = formName => {
  switch (formName) {
    case 'add_building':
    case 'edit_building':
      return object({
        name: string()
          .required('Building name is required'),
        uprn: string()
          .nullable()
          .notRequired(),
        postalCode: string()
          .nullable()
          .notRequired(),
        street: string()
          .nullable()
          .notRequired(),
        city: string()
          .nullable()
          .notRequired(),
        frsId: string()
          .nullable()
          .notRequired(),
        buildingType: object({
          type: string().required('Building type is required'),
          units: string().required('Units number is required')
        }),
        responsiblePersons: array().of(
          object({
            email: string()
              .required('Email address is required')
              .email('Incorrect email address'),
            firstName: string()
              .required('First name is required'),
            lastName: string()
              .required('Last name is required'),
            phoneNumber: string()
              .nullable()
              .test(
                'match',
                'Phone number is incorrect',
                value => !value || /^\d*$/.test(value)
              )
              .test(
                'max',
                'Phone number should not be longer than 10 characters',
                value => !value || value?.length <= 11
              )
              .test(
                'min',
                'Phone number should be at least 9 characters',
                value => !value || value?.length >= 10
              )
          })
        )
      })
    case 'wall_design':
      return object({
        isTraditionalMasonry: boolean()
          .notRequired()
          .nullable(),
        hasOuterCladding: boolean()
          .notRequired()
          .nullable(),
        isExternalMaterialCombustible: string()
          .notRequired()
          .nullable(),
        externalFacingMaterials: array()
          .notRequired()
          .nullable(),
        externalMaterialCombustibilityDetails: string()
          .notRequired()
          .nullable()
          .max(250, 'Description should not be longer than 250 characters'),
        externalWallSystems: array()
          .of(
            object({
              externalFacingMaterials: array()
                .min(1, 'This field is required'),
              isCombustible: string()
                .required('This field is required'),
              combustibilityFurtherDetails: string()
                .when('isCombustible', {
                  is: value => value === 'yes',
                  then: schema => schema
                    .required('This field is required')
                    .max(250, 'Description should not be longer than 250 characters'),
                  otherwise: schema => schema.nullable().notRequired()
                })
                .nullable()
            })
          ),
        wallFeaturesOrAttachments: array()
          .notRequired(),
        otherWallFeatures: string()
          .notRequired()
          .nullable(),
        wallFeaturesAdditionalInfo: string()
          .notRequired()
          .nullable()
          .max(250, 'Description should not be longer than 250 characters'),
        fireRiskAssessmentStatus: string()
          .notRequired()
          .nullable(),
        fireSpreadRiskLevel: string()
          .notRequired()
          .nullable(),
        areAdditionalMeasuresUnnecessary: boolean()
          .notRequired()
          .nullable(),
        fireRiskMitigationAdditionalMeasures: array()
          .notRequired()
          .nullable(),
        otherAdditionalMeasuresTaken: string()
          .notRequired()
          .nullable()
      })
    case 'faults':
    case 'plans':
      return object({
        floorsNumber: number()
          .notRequired()
          .nullable(),
        undergroundFloorsNumber: number()
          .notRequired()
          .nullable(),
        groundFloor: boolean()
          .notRequired()
          .nullable(),
        lowerGroundFloor: boolean()
          .notRequired()
          .nullable(),
        files: array()
          .notRequired()
          .nullable()
      })
    default:
      return null
  }
}

export const checkFormBeforeSave = formName => {
  const schema = getFormSchema(formName)
  const formValues = store.getState()?.form?.values
  if (schema && isNotNilOrEmpty(formValues)) {
    const externalWallSystems = pathOr([], ['externalWallSystems'], formValues)
    const responsiblePersons = pathOr([], ['responsiblePersons'], formValues)
    const withExternalWallSystems = isNotNilOrEmpty(externalWallSystems)
      ? {
        ...formValues,
        externalWallSystems: externalWallSystems.filter(system => {
          const hasMaterials = isNotNilOrEmpty(pathOr(null, ['externalFacingMaterials'], system))
          const hasIsCombustible = isNotNilOrEmpty(pathOr(null, ['isCombustible'], system))
          return hasMaterials && hasIsCombustible
        })
      }
      : formValues

    const values = isNotNilOrEmpty(responsiblePersons)
      ? {
        ...withExternalWallSystems,
        responsiblePersons: responsiblePersons.filter(rp => {
          const hasValidEmail = has('email', rp) && validateEmail(pathOr('', ['email'], rp))
          const hasFirstName = isNotNilOrEmpty(pathOr('', ['firstName'], rp))
          const hasLastName = isNotNilOrEmpty(pathOr('', ['lastName'], rp))
          return hasFirstName && hasLastName && hasValidEmail
        })
      }
      : withExternalWallSystems

    const errors = {}
    for (const key in values) {
      if (key !== 'buildingFormsState') {
        schema.validateAt(key, values[key])
          .then(() => {
          })
          .catch(err => {
            errors[key] = err.errors
          })
      }
    }
    return { errors, formValues: values }
  } else {
    return {
      errors: [],
      formValues: {}
    }
  }
}
