import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Stack, Badge } from '@mui/material'
import { FiltersIcon, CloseIcon, PlusIcon } from 'assets/icons'
import { getBuildingFilters } from 'redux/slices/dictionarySlice'
import { useAppDispatch } from 'redux/hooks'
import FloorsCompletenessFilter from 'modules/myBuildings/components/filters/FloorsCompletenessFilter'
import WallDesignCompletenessFilter from 'modules/myBuildings/components/filters/WallDesignCompletenessFilter'
import TraditionalMasonryFilter from 'modules/myBuildings/components/filters/TraditionalMasonryFilter'
import { isNotNilOrEmpty } from 'utils/ramda'
import ExternalFacingMaterialsFilter from 'modules/myBuildings/components/filters/ExternalFacingMaterialsFilter'
import IsExternalFacingMaterialCombustibleFilter
  from 'modules/myBuildings/components/filters/IsExternalFacingMaterialCombustibleFilter'
import FireSpreadRiskLevelFilter from 'modules/myBuildings/components/filters/FireSpreadRiskLevelFilter'
import FaultReportEquipmentTypesFilter from 'modules/myBuildings/components/filters/FaultReportEquipmentTypesFilter'
import qs from 'qs'
import { dissoc, keys, pathOr, propOr } from 'ramda'
import { useLocation, useSearchParams } from 'react-router-dom'
import TextButton from 'components/atoms/TextButton'

const FrsBuildingsTableFilters = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const [, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState({})
  const [counters, setCounters] = useState({
    floors_completeness: 0,
    wall_design_completeness: 0,
    is_traditional_masonry: 0,
    external_facing_materials: 0,
    is_external_material_combustible: 0,
    fire_spread_risk_level: 0,
    fault_report_equipment_types: 0
  })
  const [numberOfChosenFilters, setNumberOfChosenFilters] = useState(0)

  useEffect(() => {
    dispatch(getBuildingFilters())
  }, [])

  const queryFilters = useMemo(() => {
    const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
    const queryFilter = pathOr({}, ['filter'], parsedQuery)
    const newFilters = {}

    for (const key in queryFilter) {
      newFilters[key] = queryFilter[key].split(',').filter(v => isNotNilOrEmpty(v))
    }

    return newFilters
  }, [search])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleFilterChange = (name, query) => {
    const v = query || ''
    const counter = v.split(',').filter(v => isNotNilOrEmpty(v)).length

    setFilters(prev => ({ ...prev, [name]: query }))
    setCounters(prev => ({ ...prev, [name]: counter }))
  }

  const currentParsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const fetchUserBuildings = values => {
    let filter = pathOr({}, ['filter'], currentParsedQuery)

    let filtersPayload = {}

    keys(values).forEach(key => {
      if (isNotNilOrEmpty(values[key])) {
        filtersPayload[key] = values[key]
      } else {
        // @ts-ignore
        filtersPayload = dissoc(key, filtersPayload)
        // @ts-ignore
        filter = dissoc(key, filter)
      }
    })

    const filterPayload = {
      ...filter,
      ...filtersPayload
    }

    const query = qs.stringify({
      ...currentParsedQuery,
      filter: filterPayload
    })

    setSearchParams(query)
  }

  const handleClearAll = () => {
    const query = qs.stringify({
      ...currentParsedQuery,
      filter: {}
    })
    setSearchParams(query)
  }

  useEffect(() => {
    isNotNilOrEmpty(filters) && fetchUserBuildings(filters)
  }, [filters])

  useEffect(() => {
    const numberOfFilters = Object.values(counters).reduce(
      (acc, curr) => acc + curr,
      0
    )
    setNumberOfChosenFilters(numberOfFilters)
  }, [counters])

  return (
    <div>
      {open && <Backdrop onClick={handleClose} />}
      <Wrapper open={open}>
        <DrawerHeader open={open}>
          <FiltersIconWrapper open={open} onClick={handleOpen}>
            <Stack spacing={2} direction='row'>
              <BadgeWrapper badgeContent={numberOfChosenFilters}>
                <FiltersIcon />
              </BadgeWrapper>
            </Stack>
            <TriggerLabel>filters</TriggerLabel>
          </FiltersIconWrapper>
          <ButtonsWrapper open={open}>
            <TextButton
              label='Clear all'
              icon={<PlusIcon />}
              onClick={() => handleClearAll()}
            />
            <CloseIconWrapper>
              <StyledCloseIcon onClick={handleClose}>
                <CloseIcon />
              </StyledCloseIcon>
            </CloseIconWrapper>
          </ButtonsWrapper>
        </DrawerHeader>
        <DrawerContent open={open}>
          <FloorsCompletenessFilter
            counter={counters.floors_completeness}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'floors_completeness', queryFilters)}
          />
          <WallDesignCompletenessFilter
            counter={counters.wall_design_completeness}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'wall_design_completeness', queryFilters)}
          />
          <TraditionalMasonryFilter
            counter={counters.is_traditional_masonry}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'is_traditional_masonry', queryFilters)}
          />
          <ExternalFacingMaterialsFilter
            counter={counters.external_facing_materials}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'external_facing_materials', queryFilters)}
          />
          <IsExternalFacingMaterialCombustibleFilter
            counter={counters.is_external_material_combustible}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'is_external_material_combustible', queryFilters)}
          />
          <FireSpreadRiskLevelFilter
            counter={counters.fire_spread_risk_level}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'fire_spread_risk_level', queryFilters)}
          />
          <FaultReportEquipmentTypesFilter
            counter={counters.fault_report_equipment_types}
            onChange={handleFilterChange}
            queryFilters={propOr([], 'fault_report_equipment_types', queryFilters)}
          />
        </DrawerContent>
      </Wrapper>
    </div>
  )
}

export default FrsBuildingsTableFilters

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topNavHeight};
  right: 0;
  width: 64px;
  height: calc(100vh - ${({ theme }) => theme.dimensions.topNavHeight});
  background-color: #fff;
  transition: all 0.3s;
  border-left: 1px solid #dfe2f2;

  ${({ open }) =>
          open &&
          css`
            box-shadow: -10px 20px 24px -4px rgba(101, 118, 184, 0.08),
            -12px 8px 8px -4px rgba(101, 118, 184, 0.03);
            width: 360px;
          `}
`

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 65px;
  padding: 16px;

  ${({ open }) =>
          open &&
          css`
            flex-direction: row;
            justify-content: space-between;
            background-color: #f7f8fd;
          `}
`

const FiltersIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  ${({ open }) =>
          open &&
          css`
            flex-direction: row;
            gap: 10px;
            cursor: default;
          `}
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${({ open }) =>
          !open &&
          css`
            display: none;
          `}
`

const TriggerLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
`

const CloseIconWrapper = styled.div`
  padding-left: 14px;
  border-left: 1px solid #dfe2f2;
`

const StyledCloseIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.colors.primary};
  font-size: 18px !important;
  margin-top: 5px;
  cursor: pointer;
`

const DrawerContent = styled.div`
  padding: 16px;
  overflow-y: auto;
  // 100vh - nav height - header height
  height: calc(100vh - ${({ theme }) => theme.dimensions.topNavHeight} - 65px);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ open }) =>
          !open &&
          css`
            display: none;
          `}
`
const BadgeWrapper = styled(Badge)`
  .MuiBadge-badge {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.copper};
    left: 3px;
  }
`
