import React from 'react'
import Icon from './Icon'

export const CheckIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
       width='11'
      height='8'
      viewBox='0 0 11 8'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d="M10.6002 1.40004L4.0002 8.00004L0.700195 4.70004L1.17153 4.22871L4.0002 7.05738L10.1282 0.928711L10.5995 1.40071L10.6002 1.40004Z" />

 </Icon>
  )
}

export default CheckIcon
