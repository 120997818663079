import React from 'react'
import Icon from './Icon'

export const ArrowUpIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='12'
      height='8'
      viewBox='0 0 12 8'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.350098 6.45705L6.0071 0.800049L11.6641 6.45705L10.9571 7.16405L6.0071 2.21405L1.0571 7.16405L0.350098 6.45705Z'
      />
    </Icon>
  )
}

export default ArrowUpIcon
