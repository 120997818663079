import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { FormSeparator, InputDescription } from 'components/formComponents'
import styled from 'styled-components'
import buildingsImg from 'assets/buildings.svg'
import CounterInput from 'components/atoms/CounterInput'
import {
  setActiveStep,
  setFinishedStep,
  updateFormValues
} from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { dissoc } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { debounce } from 'lodash'
import { addFinishedFormStep } from 'utils/forms'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'

const FloorNumbersStep = () => {
  const [isValid, setIsValid] = useState(true)
  const [showError, setShowError] = useState(false)
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const formValues = useAppSelector(selectActiveFormValues)

  const defaultValues : FieldValues = {
    floorsNumber: currentBuilding.floorsNumber || '0',
    undergroundFloorsNumber: currentBuilding.undergroundFloorsNumber || '1',
    groundFloor: currentBuilding.groundFloor || false,
    lowerGroundFloor: currentBuilding.lowerGroundFloor || false,
    subterraneanStoreys: currentBuilding.undergroundFloorsNumber > 0 || false
  }

  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({ defaultValues })

  const values = watch()

  const updateValues = debounce(v => {
    dispatch(updateFormValues(v))
  }, 500)

  useEffect(() => {
    const hasFloors = Number(values.floorsNumber) > 0
    const hasUndergroundFloors =
      values.subterraneanStoreys && Number(values.undergroundFloorsNumber) > 0
    const hasGroundFloor = values.groundFloor
    const hasLowerGroundFloor = values.lowerGroundFloor

    setIsValid(
      hasFloors || hasUndergroundFloors || hasGroundFloor || hasLowerGroundFloor
    )

    const payload = {
      ...dissoc('subterraneanStoreys', values),
      floorsNumber: isNotNilOrEmpty(values.floorsNumber)
        ? Number(values.floorsNumber)
        : 0,
      undergroundFloorsNumber: values.subterraneanStoreys
        ? Number(values.undergroundFloorsNumber)
        : 0
    }
    updateValues(payload)
  }, [values])

  const submit = () => {
    dispatch(setActiveStep('floor_plans'))
    dispatch(setFinishedStep('number_of_floors'))
    dispatch(
      updateFormValues({
        buildingFormsState: addFinishedFormStep(
          'plans',
          'number_of_floors',
          formValues?.buildingFormsState || []
        )
      })
    )
  }

  const handleValidate = () => {
    if (isValid) {
      setShowError(false)
      submit()
    } else {
      setShowError(true)
    }
  }

  const floorsNumberValue = watch('floorsNumber')
  const undergroundFloorsNumberValue = watch('undergroundFloorsNumber')
  const subterraneanStoreysValue = watch('subterraneanStoreys')

  useEffect(() => {
    setValue('floorsNumber', String(floorsNumberValue).replace(/\D/gi, ''))
  }, [floorsNumberValue])

  useEffect(() => {
    setValue(
      'undergroundFloorsNumber',
      String(undergroundFloorsNumberValue).replace(/\D/gi, '')
    )
  }, [undergroundFloorsNumberValue])

  return (
    <div>
      <form onSubmit={handleSubmit(handleValidate)}>
        <SectionUp>
          <GroundFloorInputWrapper>
            <CounterInput
              register={register}
              value={floorsNumberValue}
              setValue={setValue}
              name='floorsNumber'
              label='Number of floors (above ground floor)'
              error={errors.floorsNumber?.message as string}
            />
            <InputDescription>
              If single-storey (e.g. bungalow), enter 0.
            </InputDescription>
          </GroundFloorInputWrapper>
          <Checkbox
            id='groundFloor'
            label='Ground Floor'
            error={errors.groundFloor?.message as string}
            inputProps={register('groundFloor')}
          />
        </SectionUp>
        <SectionDown>
          <Checkbox
            id='lowerGroundFloor'
            label='Lower Ground Floor'
            error={errors.lowerGroundFloor?.message as string}
            inputProps={register('lowerGroundFloor')}
          />
          <Checkbox
            id='subterraneanStoreys'
            label='Subterranean Storeys'
            error={errors.subterraneanStoreys?.message as string}
            inputProps={register('subterraneanStoreys')}
          />
          {subterraneanStoreysValue && (
            <InputWrapper>
              <CounterInput
                register={register}
                min={1}
                value={undergroundFloorsNumberValue}
                setValue={setValue}
                name='undergroundFloorsNumber'
                label='Number of Subterranean Storeys'
                error={errors.undergroundFloorsNumber?.message as string}
              />
              <InputDescription>
                Enter the number of below-ground storeys (excluding Lower
                Ground).
              </InputDescription>
            </InputWrapper>
          )}
        </SectionDown>
        {showError && <Error>You have to choose at least one option.</Error>}
        <FormSeparator />
        <ButtonWrapper>
          <Button type='submit' variant='outlined'>
            Next step
          </Button>
        </ButtonWrapper>
      </form>
    </div>
  )
}

export default FloorNumbersStep

const InputWrapper = styled.div`
  width: 280px;
  padding-left: 20px;
`

const GroundFloorInputWrapper = styled.div`
  width: 280px;
  margin-bottom: 50px;
`

const SectionUp = styled.div`
  height: 225px;
  background: transparent url('${buildingsImg}') no-repeat right;
`

const SectionDown = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-top: 2px solid #dce0f2;
  padding: 24px 16px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    width: 110px !important;
  }
`

const Error = styled.div`
  font-size: 14px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.error};
`
