import React from 'react'
import Icon from './Icon'

export const CloseIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='32'
      height='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4L14.6 16L8 22.6L9.4 24L16 17.4L22.6 24L24 22.6L17.4 16L24 9.4Z'
      />
    </Icon>
  )
}

export default CloseIcon
