import React, { useEffect, useMemo, useState } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import styled from 'styled-components'
import QuestionWithAnswers from 'modules/forms/wallsForm/components/QuestionWithAnswers'
import { FormSeparator } from 'components/formComponents'
import TrueFalseSelect from 'modules/forms/wallsForm/components/TrueFalseSelect'
import OptionSelect from 'modules/forms/wallsForm/components/OptionSelect'
import { isNotNilOrEmpty } from 'utils/ramda'
import Button from 'components/atoms/Button'
import { equals, find, pathOr, propEq, propOr } from 'ramda'
import { useAppSelector } from 'redux/hooks'
import { selectFaultsDictionary } from 'redux/selectors/dictionarySelectors'
import { StepProps } from 'utils/types'

const TypeOfEquipmentStep = ({
  onStepFinish,
  onValuesChange,
  initialValues
}: StepProps) => {
  const [lastValues, setLastValues] = useState({})
  const faultsDictionary = useAppSelector(selectFaultsDictionary)
  const [selectedOption, setSelectedOption] = useState<any>({})

  const schema = useMemo(() => {
    return object({
      equipmentTypeId: string()
        .nullable()
        .required('Equipment type is required'),
      equipmentTypeOptionValue: string().when([], {
        is: () =>
          selectedOption?.hasAdditionalOptions &&
          selectedOption.key !== 'suppression_systems' &&
          !['sprinklers', 'watermist'].includes(
            pathOr('', ['equipmentTypeOptionValue'], lastValues)
          ),
        then: schema => schema.required('This field is required').nullable(),
        otherwise: schema => schema.notRequired().nullable()
      }),
      equipmentTypeFreetext: string().when('equipmentTypeOptionValue', {
        is: value =>
          selectedOption?.hasAdditionalOptions &&
          selectedOption?.key === 'suppression_systems' &&
          !['sprinklers', 'watermist'].includes(value),
        then: schema => schema.required('This field is required').nullable(),
        otherwise: schema => schema.notRequired().nullable()
      })
    })
  }, [selectedOption, lastValues])

  const defaultValues = {
    equipmentTypeId: initialValues?.equipmentTypeId || null,
    equipmentTypeOptionValue: initialValues?.equipmentTypeOptionValue || null,
    equipmentTypeFreetext: initialValues?.equipmentTypeFreetext || null
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    trigger
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const type = watch('equipmentTypeId')
  const values = watch()

  const options = useMemo(() => {
    return faultsDictionary.map(option => {
      return {
        label: propOr('', 'name', option),
        value: propOr('', 'id', option),
        activeDescription: propOr(null, 'description', option)
      }
    })
  }, [faultsDictionary])

  useEffect(() => {
    if (!equals(lastValues, values)) {
      onValuesChange(values)
      setLastValues(values)
      if (isNotNilOrEmpty(errors)) {
        trigger()
      }
    }
  }, [values])

  useEffect(() => {
    const option = find(propEq('id', type), faultsDictionary)
    setSelectedOption(option)
  }, [type])

  const additionalForm = useMemo(() => {
    const typeKey = propOr('', 'key', selectedOption)
    switch (typeKey) {
      case 'fire_detection_and_warning_system':
        return (
          <TrueFalseSelect
            control={control}
            name='equipmentTypeOptionValue'
            onChange={value => setValue('equipmentTypeOptionValue', value)}
            title='Is the fire detection and alarm system intended to provide warning to residents?'
            description='A fire detection system intended to warn residents would need to incorporate sounders. In residential buildings, fire detection systems will be used to initiate other protective measures (e.g. smoke control systems only) and may not include sounders (i.e. a silent alarm).'
            error={errors.equipmentTypeOptionValue?.message as string}
            initialValue={values.equipmentTypeOptionValue}
          />
        )
      case 'evacuation_lifts':
        return (
          <OptionSelect
            onChange={value => setValue('equipmentTypeOptionValue', value)}
            title='Type of lift to which the fault relates'
            error={errors.equipmentTypeOptionValue?.message as string}
            initialValue={values.equipmentTypeOptionValue}
            options={[
              {
                label: 'Lift for use by firefighters',
                value: 'firefighters'
              },
              { label: 'Evacuation lift', value: 'evacuation' }
            ]}
          />
        )
      case 'rising_mains':
        return (
          <OptionSelect
            onChange={value => setValue('equipmentTypeOptionValue', value)}
            title='Type of rising mains to which the fault relates'
            error={errors.equipmentTypeOptionValue?.message as string}
            initialValue={values.equipmentTypeOptionValue}
            options={[
              { label: 'Dry rising mains', value: 'dry' },
              { label: 'Wet rising mains', value: 'wet' }
            ]}
          />
        )
      case 'smoke_control_systems':
        return (
          <OptionSelect
            onChange={value => setValue('equipmentTypeOptionValue', value)}
            title='Type of smoke control system to which the fault relates'
            error={errors.equipmentTypeOptionValue?.message as string}
            initialValue={values.equipmentTypeOptionValue}
            options={[
              { label: 'Natural ventilation', value: 'natural' },
              { label: 'Mechanical extraction', value: 'mechanical' },
              { label: 'Positive pressure', value: 'pressure' }
            ]}
          />
        )
      case 'suppression_systems':
        return (
          <QuestionWithAnswers
            onChange={value => {
              if (['sprinklers', 'watermist'].includes(value)) {
                setValue('equipmentTypeOptionValue', value)
              } else {
                setValue('equipmentTypeOptionValue', 'other')
                setValue('equipmentTypeFreetext', value)
              }
            }}
            withOther
            error={
              errors.equipmentTypeOptionValue?.message as string ||
              errors.equipmentTypeFreetext?.message as string
            }
            question='Type of suppression system to which the fault relates'
            initialRadioValue={values.equipmentTypeOptionValue}
            initialOtherValue={values.equipmentTypeFreetext}
            answers={[
              { label: 'Sprinklers', value: 'sprinklers' },
              { label: 'Watermist', value: 'watermist' }
            ]}
          />
        )
      default:
        return null
    }
  }, [selectedOption, errors, values])

  const submit = () => {
    handleSubmit(() => {
      onStepFinish()
    })()
  }

  return (
    <div>
      <ExpandableInfo title='Reporting a fault'>
        <div>
          Regulation 7 of the Fire Safety (England) Regulations 2022 requires
          the Responsible Person of all high-rise residential buildings to
          inform their local Fire and Rescue Service where faults with key
          firefighting equipment are identified that cannot be rectified within
          24 hours. The local Fire and Rescue Service should be notified again
          once the fault has been rectified.
        </div>
      </ExpandableInfo>
      <QuestionWithAnswers
        onChange={value => {
          setValue('equipmentTypeId', value)
          setValue('equipmentTypeOptionValue', null)
        }}
        question='What type of equipment does the fault relate to?'
        initialRadioValue={initialValues.equipmentTypeId}
        answers={options}
        error={errors.equipmentTypeId?.message as string}
      />
      {isNotNilOrEmpty(additionalForm) && (
        <>
          <FormSeparator />
          <FormWrapper>{additionalForm}</FormWrapper>
        </>
      )}
      <FormSeparator />
      <ButtonsWrapper>
        <Button onClick={submit} variant='outlined'>
          Next step
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default TypeOfEquipmentStep

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    width: 170px !important;
  }
`

const FormWrapper = styled.div`
  margin-top: 20px;
  padding: 12px;
  background-color: #f7f8fd;
  border-radius: 4px;
`
