import React, { useEffect, useMemo, useState } from 'react'
import FiltersSection from 'modules/myBuildings/components/filters/FiltersSection'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingFilters } from 'redux/selectors/dictionarySelectors'
import { keys, reverse, equals, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { snakeCase } from 'lodash'
import styled from 'styled-components'
import Checkbox from 'components/atoms/Checkbox'

const TraditionalMasonryFilter = ({ onChange, counter, queryFilters }) => {
  const buildingFilters = useAppSelector(selectBuildingFilters)
  const filters = pathOr([], ['isTraditionalMasonry'], buildingFilters)
  const [values, setValues] = useState({})
  const [lastChosen, setLastChosen] = useState({})

  useEffect(() => {
    if (isNotNilOrEmpty(values)) {
      const query = keys(values)
        .map(key => (values[key] ? snakeCase(key) : null))
        .filter(v => isNotNilOrEmpty(v))
        .join(',')
      onChange('is_traditional_masonry', query)
    }
  }, [values])

  const handleChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!equals(queryFilters, lastChosen)) {
      const newValues = {}
      for (const key in values) {
        newValues[key] = queryFilters.includes(key)
      }
      setValues(newValues)
      setLastChosen(queryFilters)
    }
  }, [queryFilters])

  useEffect(() => {
    const newFilters = {}
    if (isNotNilOrEmpty(queryFilters)) {
      queryFilters.forEach(v => {
        newFilters[v] = true
      })
      setValues(prev => ({ ...prev, ...newFilters }))
    }
  }, [])

  const content = useMemo(() => {
    if (isNotNilOrEmpty(filters)) {
      return reverse(filters).map((filter, index) => {
        return (
          <Checkbox
            id={`is_traditional_masonry-${filter}`}
            key={`is_traditional_masonry-${filter}`}
            label={
              index === 0 ? (
                <Option>
                  <OptionTitle>
                    Traditional masonry materials ({filter})
                  </OptionTitle>
                  <OptionDescription>
                    Non-combustible: such a brick, block or concrete
                  </OptionDescription>
                </Option>
              ) : (
                `None (${filter})`
              )
            }
            inputProps={{
              disabled: filter === 0,
              onChange: e =>
                handleChange(index === 0 ? '1' : '0', e.target.checked),
              checked: values[index === 0 ? '1' : '0'] || false
            }}
          />
        )
      })
    } else {
      return null
    }
  }, [filters, queryFilters, values])

  return (
    <FiltersSection title='Traditional Masonry Construction' counter={counter}>
      {content}
    </FiltersSection>
  )
}

export default TraditionalMasonryFilter

const Option = styled.div`
  margin-top: -1px;
`

const OptionTitle = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
`

const OptionDescription = styled.div`
  font-size: 12px;
`
