import React, { useState } from 'react'
import styled from 'styled-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'

const RemoveAddedFileModal = ({ onRemove }: { onRemove: () => void }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleRemove = () => {
    onRemove()
    handleClose()
  }

  return (
    <>
      <DeleteIcon onClick={handleOpen} />
      <Modal title='Remove uploaded file' onClose={handleClose} open={open}>
        Do you really want to remove uploaded plan?
        <ButtonsWrapper>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleRemove}>Remove</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default RemoveAddedFileModal

const DeleteIcon = styled(DeleteOutlinedIcon)`
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  gap: 15px;

  button {
    width: fit-content;
  }
`
