import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import React from 'react'
import { toast } from 'react-hot-toast'
import { useAppDispatch } from 'redux/hooks'
import { getFaults } from 'redux/slices/faultsSlice'
import { removeFault } from 'services/FaultsService'
import styled from 'styled-components'
import { getApiErrors } from 'utils/errors'
import qs from 'qs'

type RemoveFaultModalTypes = {
  open: boolean
  onClose: () => void
  id: string
  buildingId: string
}

const RemoveFaultModal = ({ open, onClose, id, buildingId } : RemoveFaultModalTypes) => {
  const dispatch = useAppDispatch()
  const deleteFault = () => {
    removeFault({ buildingId, id })
      .then(() => {
        const query = {
          limit: 20,
          page: 1,
          filter: { is_rectified: false }
        }
        toast.success('Fault draft has been successfully deleted')
        dispatch(getFaults({ id: buildingId, query: qs.stringify(query) }))
        onClose()
      })
      .catch(e => toast.error(getApiErrors(e)))
  }

  return (
    <Modal open={open} title='Remove fault report' onClose={onClose}>
      Do you want to remove unfinished fault report?
      <ButtonsWrapper>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={deleteFault}>Remove draft</Button>
      </ButtonsWrapper>
    </Modal>
  )
}

export default RemoveFaultModal

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  gap: 10px;

  button {
    width: fit-content;
  }
`
