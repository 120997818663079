import React from 'react'
import styled from 'styled-components'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import FloorBadges from 'modules/buildingDetails/components/FloorBadges'
import EditFloorPlanModal from 'modules/forms/plansForm/components/EditFloorPlanModal'
import RemoveAddedFileModal from 'modules/forms/plansForm/components/RemoveAddedFileModal'

type AddedFileTypes = {
  file: any
  onRemove?: (url?: string) => void
  onEdit?: (url?: string) => void
  isFloorPlan?: boolean
}

const AddedFile = ({
  file,
  onRemove = () => {},
  onEdit,
  isFloorPlan
}: AddedFileTypes) => {
  return (
    <Wrapper>
      <InsideWrapper>
        <Icon>
          <FileIcon />
        </Icon>
        <FileDetailsWrapper>
          <FileName>{file?.displayName}.pdf</FileName>
          {isFloorPlan && (
            <FloorsWrapper>
              APPLICABLE FLOORS:{' '}
              <FloorBadges floorNumbers={file.floorNumbers} />
            </FloorsWrapper>
          )}
        </FileDetailsWrapper>
        {isFloorPlan && <EditFloorPlanModal file={file} onChange={onEdit} />}
      </InsideWrapper>
      <BinWrapper>
        <RemoveAddedFileModal onRemove={onRemove} />
      </BinWrapper>
    </Wrapper>
  )
}

export default AddedFile

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 68px;
  margin-bottom: 16px;
`

const Icon = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#fae4de 50%, #fcf4f2 30%);
  border-radius: 50%;
`

const InsideWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  height: 100%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px;
`

const BinWrapper = styled.div`
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
`

const FileName = styled.div`
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const FileDetailsWrapper = styled.div`
  width: 100%;
`

const FloorsWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 10px;
  align-items: center;
`
