import { findIndex, pathOr, propEq, update } from 'ramda'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'

type FormType = string | null

export const getFormContent = (activeForm: FormType) => {
  switch (activeForm) {
    case 'add_building':
      return {
        title: 'Report to FRS - Add building',
        steps: [
          {
            title: 'Building Information',
            key: 'building_information'
          },
          {
            title: 'Responsible Person(s)',
            key: 'responsible_persons'
          }
        ]
      }
    case 'edit_building':
      return {
        title: 'Report to FRS - Edit building',
        steps: [
          {
            title: 'Building Information',
            key: 'building_information'
          },
          {
            title: 'Responsible Person(s)',
            key: 'responsible_persons'
          }
        ]
      }
    case 'plans':
      return {
        title: 'Report to FRS - Floor and Building Plans',
        steps: [
          {
            title: 'Number of Floors',
            key: 'number_of_floors'
          },
          {
            title: 'Floor Plans',
            key: 'floor_plans'
          },
          {
            title: 'Building Plans',
            key: 'building_plans'
          }
        ]
      }
    case 'wall_design':
      return {
        title: 'Report to FRS - External Wall Design',
        steps: [
          {
            title: 'Traditional Masonry Construction',
            key: 'traditional_masonry_construction'
          },
          {
            title: 'External Wall System',
            key: 'external_wall_system'
          },
          {
            title: 'Wall Features and Attachments',
            key: 'wall_features_and_attachments'
          },
          {
            title: 'Risk and Mitigation',
            key: 'risk_and_mitigation'
          }
        ]
      }
    case 'faults':
      return {
        title: 'Report to FRS - Faults',
        steps: [
          {
            title: 'Type of Equipment',
            key: 'type_of_equipment'
          },
          {
            title: 'Details of Fault',
            key: 'details_of_fault'
          },
          {
            title: 'Fault Mitigation',
            key: 'fault_mitigation'
          }
        ]
      }
    case 'rectification':
      return {
        title: 'Report to FRS - Rectification of Fire Safety Equipment Fault',
        steps: []
      }
    default:
      return {
        title: '',
        steps: []
      }
  }
}

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const updateFormState = (formName, steps, currentState = []) => {
  if (isNotNilOrEmpty(currentState)) {
    const index = findIndex(propEq('formName', formName), currentState)
    if (index && index >= 0) {
      return update(index, { formName, steps }, currentState)
    } else {
      return [
        ...currentState,
        { formName, steps }
      ]
    }
  } else {
    return [
      { formName, steps }
    ]
  }
}

export const addFinishedFormStep = (formName, step, currentState = []) => {
  if (isNotNilOrEmpty(currentState)) {
    const index = findIndex(propEq('formName', formName), currentState)
    if (isNotNil(index) && index >= 0) {
      const currentFormState = currentState[index]
      const steps: string[] = pathOr([],['steps'], currentFormState)
      const isOnTheList = steps.includes(step)

      if (!isOnTheList) {
        return update(index, { formName, steps: [...steps, step] }, currentState)
      } else {
        return [
          ...currentState
        ]
      }
    } else {
      return [
        ...currentState,
        { formName, steps: [step] }
      ]
    }
  } else {
    return [
      { formName, steps: [step] }
    ]
  }
}
