import React, { useState } from 'react'
import styled from 'styled-components'
import RegisterFrsForm from 'modules/auth/RegisterFrsForm'
import RegisterUser from 'modules/auth/RegisterUserForm'
import AuthFormWrapper from 'modules/auth/AuthFormWrapper'
import theme from 'utils/theme'
import Checkbox from 'components/atoms/Checkbox'

const Register = () => {
  const [isFRS, setIsFRS] = useState(false)

  const toggleType = () => {
    setIsFRS(prev => !prev)
  }

  return (
    <AuthFormWrapper>
      <FormWrapper>
        <HeaderText>
          Let’s create a safer&nbsp;
          <span>Built Environment</span>
        </HeaderText>
        <HeaderExtraText>
          In order to submit information securely you will need to set up a free
          account with <span>Building Passport</span>.
        </HeaderExtraText>
        <TypeWrapper>
          <CheckboxWrapper>
            <Checkbox
              id='isFrs'
              label='I am a Fire and Rescue Service worker'
              color={theme.colors.teal}
              colorBg={theme.colors.lightGreen}
              noMargin
              inputProps={{ onChange: toggleType }}
            />
          </CheckboxWrapper>
        </TypeWrapper>
        {isFRS ? <RegisterFrsForm /> : <RegisterUser />}
      </FormWrapper>
    </AuthFormWrapper>
  )
}

export default Register

const FormWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  padding: 35px 70px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.dimensions.topNavHeight};
`
const HeaderText = styled.h1`
  font-size: 30px;
  margin-bottom: 12px;

  span {
    font-weight: bold;
  }
`
const HeaderExtraText = styled.div`
  margin-bottom: 20px;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.copper};
  }
`
const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.copperBg};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 20px;
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`
