import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import styled from 'styled-components'
import QuestionWithAnswers from 'modules/forms/wallsForm/components/QuestionWithAnswers'
import { FormSeparator } from 'components/formComponents'
import TrueFalseSelect from 'modules/forms/wallsForm/components/TrueFalseSelect'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { equals } from 'ramda'
import { yupResolver } from '@hookform/resolvers/yup'
import { boolean, object, string } from 'yup'
import { isNotNilOrEmpty } from 'utils/ramda'
import { StepProps } from 'utils/types'

const schema = object({
  additionalMeasuresImplemented: boolean()
    .nullable()
    .required('This field is required'),
  evacuationStrategyChange: string()
    .nullable()
    .required('This field is required'),
  additionalMeasuresDetails: string().when('additionalMeasuresImplemented', {
    is: true,
    then: schema => schema.required('This field is required').nullable(),
    otherwise: schema => schema.notRequired().nullable()
  })
})
interface FaultMitigationStepProps extends StepProps {
  onSaveAndNew: () => void
  onGoBack: () => void
  isEditForm: boolean
}

const FaultMitigationStep = ({
  onStepFinish,
  onSaveAndNew,
  onGoBack,
  onValuesChange,
  initialValues,
  isEditForm
}: FaultMitigationStepProps) => {
  const [lastValues, setLastValues] = useState({})

  const defaultValues: FieldValues = {
    additionalMeasuresImplemented:
      initialValues?.additionalMeasuresImplemented || undefined,
    evacuationStrategyChange:
      initialValues?.evacuationStrategyChange?.value || undefined,
    additionalMeasuresDetails: initialValues?.additionalMeasuresDetails || ''
  }

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    formState: { errors },
    trigger
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const submit = () => {
    handleSubmit(() => {
      onStepFinish()
    })()
  }

  const submitAndCreateNew = () => {
    handleSubmit(() => {
      onSaveAndNew()
    })()
  }

  const additional = watch('additionalMeasuresImplemented')
  const values = watch()

  useEffect(() => {
    if (!equals(lastValues, values)) {
      onValuesChange(values)
      setLastValues(values)
      if (isNotNilOrEmpty(errors)) {
        trigger()
      }
    }
  }, [values])

  return (
    <div>
      <QuestionWithAnswers
        onChange={value => setValue('evacuationStrategyChange', value)}
        question='Has the fault necessitated a temporary change in evacuation strategy from stay put to simultaneous?'
        error={errors.evacuationStrategyChange?.message as string}
        initialRadioValue={values.evacuationStrategyChange}
        answers={[
          {
            label: 'Yes',
            value: 'yes'
          },
          {
            label: 'No',
            value: 'no'
          },
          {
            label: 'The building already has a simultaneous evacuation policy',
            value: 'already_simultaneous'
          }
        ]}
      />
      <TrueFalseSelect
        control={control}
        name='additionalMeasuresImplemented'
        title='Have any additional measures been implemented to mitigate against the fault  reported?'
        onChange={value => setValue('additionalMeasuresImplemented', value)}
        initialValue={initialValues.additionalMeasuresImplemented}
        error={errors.additionalMeasuresImplemented?.message as string}
      />
      {additional && (
        <FormWrapper>
          <Input
            register={register}
            multiline
            placeholder='Enter a description...'
            name='additionalMeasuresDetails'
            label='Provide details on additional measures'
            error={errors.additionalMeasuresDetails?.message as string}
          />
        </FormWrapper>
      )}
      <FormSeparator />
      <ButtonsWrapper>
        <Button variant='secondary' onClick={onGoBack}>
          Back
        </Button>
        <SubmitButtons>
          {!isEditForm && (
            <Button variant='outlined' onClick={submitAndCreateNew}>
              Submit and Add New
            </Button>
          )}
          <Button onClick={submit}>Submit Fault</Button>
        </SubmitButtons>
      </ButtonsWrapper>
    </div>
  )
}

export default FaultMitigationStep

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    min-width: 100px;
    width: fit-content !important;
  }
`

const SubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`

const FormWrapper = styled.div`
  margin-top: 20px;
  padding: 12px;
  background-color: #f7f8fd;
  border-radius: 4px;
`
