import React, { useEffect, useMemo } from 'react'
import ExpandableInfo from 'components/atoms/ExpandableInfo'
import TrueFalseSelect from 'modules/forms/wallsForm/components/TrueFalseSelect'
import { FormSeparator, InputDescription } from 'components/formComponents'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, boolean, object, string } from 'yup'
import Select from 'components/atoms/Select'
import styled from 'styled-components'
import Input from 'components/atoms/Input'
import {
  setActiveStep,
  setFinishedStep,
  updateFormValues
} from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectExternalFacingMaterials } from 'redux/selectors/dictionarySelectors'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { addFinishedFormStep } from 'utils/forms'
import { selectActiveFormValues } from 'redux/selectors/formSelectors'
import Button from 'components/atoms/Button'
import { equals, keys, pick } from 'ramda'

const schema = object({
  isTraditionalMasonry: boolean().required('This field is required').nullable(),
  hasOuterCladding: boolean().when('isTraditionalMasonry', {
    is: true,
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema.nullable().notRequired()
  }),
  isExternalMaterialCombustible: string().when('hasOuterCladding', {
    is: true,
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema.nullable().notRequired()
  }),
  externalFacingMaterials: array().when('hasOuterCladding', {
    is: true,
    then: schema => schema.min(1, 'This field is required'),
    otherwise: schema => schema.nullable().notRequired()
  }),
  externalMaterialCombustibilityDetails: string().when(
    'isExternalMaterialCombustible',
    {
      is: (value: string)=> value === 'yes',
      then: schema =>
        schema
          .required('This field is required')
          .max(250, 'Description should not be longer than 250 characters'),
      otherwise: schema => schema.nullable().notRequired()
    }
  )
})

const TraditionalMasonryConstructionStep = () => {
  const dispatch = useAppDispatch()
  const building = useAppSelector(selectCurrentBuilding)
  const defaultValues : FieldValues = {
    isTraditionalMasonry: building?.isTraditionalMasonry,
    hasOuterCladding: building?.hasOuterCladding,
    isExternalMaterialCombustible:
    building.isExternalMaterialCombustible?.value,
    externalFacingMaterials: (building?.externalFacingMaterials || []).map(
      v => v.id
    ),
    externalMaterialCombustibilityDetails:
      building?.externalMaterialCombustibilityDetails || ''
  }
  const formValues = useAppSelector(selectActiveFormValues)

  useEffect(() => {
    dispatch(updateFormValues(defaultValues))
  }, [])

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    register
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const externalFacingMaterials = useAppSelector(selectExternalFacingMaterials)

  const values = watch()
  const selectedMaterials = watch('externalFacingMaterials')

  useEffect(() => {
    const valuesKeys = keys(defaultValues)
    const picked = { ...pick(valuesKeys, formValues || {}) }

    const isEqual = valuesKeys.every(key => {
      return equals(picked[key] || null, values[key] || null)
    })

    if (!isEqual) {
      dispatch(updateFormValues(values))
    }
  }, [values])

  const materialOptions = useMemo(() => {
    return (externalFacingMaterials || []).map(material => ({
      label: material.name,
      value: material.id
    }))
  }, [externalFacingMaterials])

  const isTraditionalMasonry = watch('isTraditionalMasonry')
  const hasOuterCladding = watch('hasOuterCladding')
  const isExternalMaterialCombustible = watch('isExternalMaterialCombustible')

  const submit = () => {
    handleSubmit(data => {
      dispatch(
        updateFormValues({
          ...data,
          buildingFormsState: addFinishedFormStep(
            'wall_design',
            'traditional_masonry_construction',
            formValues?.buildingFormsState || []
          )
        })
      )
      dispatch(setActiveStep('external_wall_system'))
      dispatch(setFinishedStep('traditional_masonry_construction'))
    })()
  }

  const handleMaterialDeselect = value => {
    const filteredValue = selectedMaterials.filter(material => material !== value)
    setValue('externalFacingMaterials', filteredValue)
  }

  return (
    <>
      <ExpandableInfo
        title='What is Traditional Masonry Construction?'
        shortDescription={null}
      >
        <div>
          Traditional masonry construction refers to the use of non-combustible
          materials such as brick, block or concrete. This will also include
          buildings constructed using these materials which have been clad or
          finished with additional materials
        </div>
      </ExpandableInfo>
      <TrueFalseSelect
        control={control}
        initialValue={isTraditionalMasonry}
        name='isTraditionalMasonry'
        error={errors.isTraditionalMasonry?.message as string}
        title='Are the external walls of the building constructed (in part or in full) using traditional masonry materials?'
        onChange={value => {
          setValue('isTraditionalMasonry', value)
          if (!value) {
            setValue('hasOuterCladding', undefined)
            setValue('isExternalMaterialCombustible', undefined)
            setValue('externalFacingMaterials', [])
            setValue('externalMaterialCombustibilityDetails', '')
          }
        }}
      />
      {isTraditionalMasonry && (
        <>
          <FormSeparator />
          <TrueFalseSelect
            control={control}
            name='hasOuterCladding'
            initialValue={hasOuterCladding}
            error={errors.hasOuterCladding?.message as string}
            title='Is there any form of cladding or finish present over the outer masonry layer?'
            description='This could be as part of the original building design or a result of a subsequent building refurbishment'
            onChange={value => {
              setValue('hasOuterCladding', value)
              if (!value) {
                setValue('isExternalMaterialCombustible', undefined)
                setValue('externalFacingMaterials', [])
                setValue('externalMaterialCombustibilityDetails', '')
              }
            }}
          />
        </>
      )}
      {hasOuterCladding && (
        <FormWrapper>
          <Select
            label='Select external facing materials which are present over the outer masonry layer'
            control={control}
            noMarginBottom
            multiple
            onItemDeselect={handleMaterialDeselect}
            name='externalFacingMaterials'
            options={materialOptions}
            error={errors.externalFacingMaterials?.message as string}
          />
          <FormSeparator />
          <TrueFalseSelect
            isColumn
            addNotSureOption
            control={control}
            initialValue={isExternalMaterialCombustible}
            error={errors.isExternalMaterialCombustible?.message as string}
            name='isExternalMaterialCombustible'
            title='Are these walls likely to ignite and spread fire easily?'
            description='Consideration should be given to the combustibility of the external facing material (above), combustibility of any insulation, and any confirmed issues with the construction methods.'
            onChange={value => {
              setValue('isExternalMaterialCombustible', value)
              if (value !== 'yes') {
                setValue('externalMaterialCombustibilityDetails', '')
              }
            }}
          />
          {isExternalMaterialCombustible === 'yes' && (
            <>
              <Input
                name='externalMaterialCombustibilityDetails'
                multiline
                register={register}
                label='Provide further details'
                placeholder='Enter a description...'
                error={errors.externalMaterialCombustibilityDetails?.message as string}
              />
              <InputDescription>
                Include the reason why the wall will contribute to fire spread
                (e.g. what materials are combustible), and where appropriate,
                how these sections of wall can be distinguished from those which
                will not promote fire spread.
              </InputDescription>
            </>
          )}
        </FormWrapper>
      )}
      <FormSeparator />
      <ButtonWrapper>
        <Button variant='outlined' onClick={submit}>
          Next step
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default TraditionalMasonryConstructionStep

const FormWrapper = styled.div`
  margin-top: 20px;
  padding: 12px;
  background-color: #f7f8fd;
  border-radius: 4px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    width: 150px !important;
  }
`
