import React from 'react'
import styled from 'styled-components'
import Footer from 'modules/layout/Footer'
import logoSimple from 'assets/logo-simple.svg'

const AuthFormWrapper = ({ children }) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <LogoWrapper>
          Powered by <Logo src={logoSimple} />
        </LogoWrapper>
        {children}
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  )
}

export default AuthFormWrapper

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ContentWrapper = styled.section`
  min-height: ${({ theme }) =>
          `calc(100vh - ${theme.dimensions.topNavHeight})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Logo = styled.img`
  height: 24px;
  margin-left: 8px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightTrout};
  margin: 16px 0;
`
