import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { PlansIcon, WallsIcon, FaultsIcon, PlusIcon } from 'assets/icons'
import DecorativeButton from 'components/atoms/DecorativeButton'

const MyBuildingsEmptyState = ({ openAddModal } : { openAddModal: MouseEventHandler }) => {
  return (
    <Wrapper>
      <Title>You have no buildings yet</Title>
      <Description>
        Add your first building and share its information in accordance with the{' '}
        <a
          href='https://www.gov.uk/government/publications/fire-safety-england-regulations-2022'
          rel='noreferrer'
          target='_blank'
        >
          Fire Safety (England) Regulations
        </a>
        . After building creation you will be able to submit the following:
      </Description>
      <Boxes>
        <BoxWrapper>
          <IconWrapper>
            <PlansIcon />
          </IconWrapper>
          <ContentWrapper>
            <BoxTitle>Plans</BoxTitle>
            <BoxDescription>
              Submit a record of floor and building plans
            </BoxDescription>
          </ContentWrapper>
        </BoxWrapper>
        <BoxWrapper>
          <IconWrapper>
            <WallsIcon />
          </IconWrapper>
          <ContentWrapper>
            <BoxTitle>Wall design</BoxTitle>
            <BoxDescription>
              Submit a record of the design and construction of external walls
            </BoxDescription>
          </ContentWrapper>
        </BoxWrapper>
        <BoxWrapper>
          <IconWrapper>
            <FaultsIcon />
          </IconWrapper>
          <ContentWrapper>
            <BoxTitle>Faults / Repairs</BoxTitle>
            <BoxDescription>
              Report a fault or repair with fire safety equipment
            </BoxDescription>
          </ContentWrapper>
        </BoxWrapper>
      </Boxes>
      <ButtonWrapper>
        <DecorativeButton onClick={openAddModal}>
          <PlusIconWrapper /> Add new building
        </DecorativeButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default MyBuildingsEmptyState

const Wrapper = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(101, 118, 184, 0.08),
  0 2px 4px rgba(101, 118, 184, 0.12), 0 8px 16px rgba(101, 118, 184, 0.06),
  0 1px 2px rgba(101, 118, 184, 0.06);
`

const Title = styled.div`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`

const Description = styled.div`
  font-size: 18px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

const Boxes = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 24px;
`

const BoxWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.background};
`

const IconWrapper = styled.div`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`

const BoxTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const BoxDescription = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.trout};
  min-height: 34px;
`

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`
const PlusIconWrapper = styled(PlusIcon)`
  margin-right: 5px;
  fill: ${({ theme }) => theme.colors.white};
`
