import React, { useMemo } from 'react'
import ContentPanel from 'components/atoms/ContentPanel'
import styled from 'styled-components'
import { pathOr, propOr } from 'ramda'
import FormBox from 'modules/buildingDetails/components/FormBox'
import FormModal from 'components/atoms/FormModal'
import { SectionTitle } from 'components/formComponents'
import { setActiveForm } from 'redux/slices/formSlice'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { selectCurrentUser } from 'redux/selectors/authSelectors'
import { PlansIcon, WallsIcon, FaultsIcon, EditIcon } from 'assets/icons'
import { isNotNilOrEmpty } from 'utils/ramda'
import TextButton from 'components/atoms/TextButton'
import { getBuildingAddress } from 'utils/building'

const BuildingInfo = () => {
  const dispatch = useAppDispatch()
  const building = useAppSelector(selectCurrentBuilding)

  const floorsCompletionPercentage: number = propOr(
    null,
    'floorsCompletionPercentage',
    building
  )
  const wallDesignCompletionPercentage: number = propOr(
    null,
    'wallDesignCompletionPercentage',
    building
  )

  const currentUser = useAppSelector(selectCurrentUser)
  const isFrs =  pathOr(null, ['type'], currentUser) === 'emergency_service'
 
  const handleOpenEdit = () => {
    dispatch(setActiveForm('edit_building'))
  }

  const handleFormModalOpen = formName => () => {
    dispatch(setActiveForm(formName))
  }

  const address = useMemo(() => {
    return getBuildingAddress(building)
  }, [building])

  return building ? (
    <ContentPanel>
      <HeaderWrapper>
        <Header>
          <BuildingName>{building?.name}</BuildingName>
          <BuildingAddress>{address}</BuildingAddress>
        </Header>
        {!isFrs && (
          <TextButton
            label='Edit'
            onClick={handleOpenEdit}
            icon={<EditIcon />}
          />
        )}
      </HeaderWrapper>
      {isNotNilOrEmpty(building?.frs) && (
        <ResponsibleInfo>
          <ResponsibleTitle>Responsible FRS</ResponsibleTitle>
          <ResponsibleName>{building?.frs?.name}</ResponsibleName>
          <ResponsibleLink
            href={`http://${building?.frs?.domain}`}
            target='_blank'
            rel='noreferrer'
          >
            {building?.frs?.domain}
          </ResponsibleLink>
        </ResponsibleInfo>
      )}
      <SectionTitle>forms</SectionTitle>
      <FormsWrapper>
        <FormBox
          title='Plans'
          formKey='plans'
          description='Submit a record of floor and building plans'
          icon={
            <IconWrapper>
              <PlansIcon />
            </IconWrapper>
          }
          progress={floorsCompletionPercentage}
          onClick={handleFormModalOpen('plans')}
        />
        <FormBox
          title='Wall Design'
          formKey='wall_design'
          description='Submit a record of the design and construction of external walls'
          icon={
            <IconWrapper>
              <WallsIcon />
            </IconWrapper>
          }
          progress={wallDesignCompletionPercentage}
          onClick={handleFormModalOpen('wall_design')}
        />
        <FormBox
          title='Faults / Repairs'
          description='Report a fault or repair with fire safety equipment'
          formKey='faults'
          icon={
            <IconWrapper>
              <FaultsIcon />
            </IconWrapper>
          }
          onClick={handleFormModalOpen('faults')}
        />
      </FormsWrapper>
      <FormModal />
    </ContentPanel>
  ) : null
}

export default BuildingInfo

const Header = styled.div`
  display: flex;
  gap: 14px;
  align-items: baseline;
`

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BuildingName = styled.div`
  font-size: 35px;
  font-weight: bold;
`

const BuildingAddress = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  font-size: 16px;
`

const ResponsibleInfo = styled.div`
  margin-bottom: 16px;
`

const ResponsibleTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.trout};
  margin-bottom: 3px;
  letter-spacing: 0.005em;
`

const ResponsibleName = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const ResponsibleLink = styled.a`
  display: inline-block;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.primary};
`

const FormsWrapper = styled.div`
  display: flex;
  gap: 12px;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.lightCopper};
`
