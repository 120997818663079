import React, { ReactNode } from 'react'
import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'

type Option = {
  label: string
  value: string | number
}

interface AutocompleteInterface {
  options: Option[]
  error?: string
  label?: string
  placeholder?: string
  control: any
  name: string
  type?: string
  additionalLabel?: ReactNode
}

const Autocomplete = (props: AutocompleteInterface) => {
  const { error, label, name, additionalLabel, options, control } = props

  return (
    <Wrapper>
      {label && (
        <LabelWrapper>
          <Label>
            {label}
          </Label>
          {additionalLabel && additionalLabel}
        </LabelWrapper>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <MaterialAutocomplete
            options={options}
            disablePortal
            onChange={(_, values) => onChange(values)}
            value={value}
            renderInput={(params) => (
              <TextField
                {...params}
                size='small'
                onChange={onChange}
              />
            )}
          />
        )}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default Autocomplete

const Wrapper = styled.div`
  margin-bottom: 15px;

  .MuiInputBase-root {
    height: 44px;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }

  .MuiAutocomplete-root:focus-visible, .MuiFormControl-root:focus-visible, .MuiTextField-root:focus-visible {
    border: none;
    outline: none;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Error = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`
