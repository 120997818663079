import React, { MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import { CloseIcon } from 'assets/icons'

type FormModalWrapperTypes = {
  open: boolean
  title: string
  onClose: MouseEventHandler
  children: ReactNode
}

const FormModalWrapper = ({ open, title, onClose, children } : FormModalWrapperTypes) => {
  return open ? (
    <Backdrop onClick={onClose}>
      <FakeModal onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalActions>
            <IconWrapper onClick={onClose}>
              <CloseIcon />
            </IconWrapper>
          </ModalActions>
        </ModalHeader>
        <ModalContent>
          <ContentWrapper>
            <Content>{children}</Content>
          </ContentWrapper>
        </ModalContent>
      </FakeModal>
    </Backdrop>
  ) : null
}

export default FormModalWrapper

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
`

const FakeModal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
`

const ModalHeader = styled.div`
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafbfc;
  filter: drop-shadow(0px 0px 4px rgba(101, 118, 184, 0.08))
    drop-shadow(0px 2px 4px rgba(101, 118, 184, 0.12))
    drop-shadow(0px 8px 16px rgba(101, 118, 184, 0.06))
    drop-shadow(0px 1px 2px rgba(101, 118, 184, 0.06));
  border-bottom: 1px solid #dce0f2;
  border-radius: 8px 8px 0 0;
`

const ModalTitle = styled.div`
  font-size: 24px;
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`

const ModalContent = styled.div`
  display: flex;
  height: calc(100% - 80px);
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
`

const Content = styled.div`
  width: 860px;
  margin: 0 auto;
`
const IconWrapper = styled.div`
  fill: ${({ theme }) => theme.colors.trout};
`
