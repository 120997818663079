import React from 'react'
import Icon from './Icon'

export const ArrowRightIcon = (props: { [x: string]: string }) => {
  return (
    <Icon
      width='7'
      height='10'
      viewBox='0 0 7 10'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M1.37286 9.67833L6.08702 4.96417L1.37286 0.25L0.783691 0.839167L4.90869 4.96417L0.783691 9.08917L1.37286 9.67833Z' />
    </Icon>
  )
}

export default ArrowRightIcon
