import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import logoShort from 'assets/logo-short.png'
import AddBuildingForm from 'modules/forms/addBuildingForm/AddBuildingForm'
import PlansForm from 'modules/forms/plansForm/PlansForm'
import WallsForm from 'modules/forms/wallsForm/WallsForm'
import { SectionTitle } from 'components/formComponents'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  clearActiveForm,
  setActiveStep,
  updateFinishedSteps,
  updateFormValues,
  updateVisibleSteps
} from 'redux/slices/formSlice'
import {
  selectActiveForm,
  selectActiveStep,
  selectFinishedSteps,
  selectFormSteps,
  selectFormTitle,
  selectFormVisibleSteps
} from 'redux/selectors/formSelectors'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import Modal from 'components/atoms/Modal'
import { selectCurrentBuilding } from 'redux/selectors/buildingSelectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import EditBuildingForm from 'modules/forms/editBuildingForm/EditBuildingForm'
import BuildingDetailsInForm from 'components/atoms/BuildingDetailsInForm'
import {CheckIcon, CloseIcon} from 'assets/icons'
import { checkFormBeforeSave } from 'utils/drafts'
import toast from 'react-hot-toast'
import {head, keys, pathOr} from 'ramda'
import {
  addBuilding,
  editBuilding, getBuildingDetails,
  getUserBuildings
} from 'redux/slices/buildingSlice'
import { getFormContent } from 'utils/forms'
import theme from 'utils/theme'
import CloseFormModal from 'components/atoms/CloseFormModal'
import Button from 'components/atoms/Button'

const FormModal = () => {
  const [saveAsDraftModalOpen, setSaveAsDraftModalOpen] = useState(false)
  const currentBuilding = useAppSelector(selectCurrentBuilding)
  const dispatch = useAppDispatch()
  const activeForm = useAppSelector(selectActiveForm)
  const activeStep = useAppSelector(selectActiveStep)
  const formSteps = useAppSelector(selectFormSteps)
  const finishedSteps = useAppSelector(selectFinishedSteps)
  const formTitle = useAppSelector(selectFormTitle)
  const isAddBuildingForm = activeForm === 'add_building'
  const visibleSteps = useAppSelector(selectFormVisibleSteps)

  const isStepVisible = stepName => visibleSteps.includes(stepName)

  const changeStep = stepName => () => {
    if (isStepVisible(stepName)) {
      dispatch(setActiveStep(stepName))
    }
  }

  useEffect(() => {
    const formSteps = getFormContent(activeForm)?.steps?.map(s => s.key)

    if (isNotNilOrEmpty(currentBuilding) && !isAddBuildingForm) {
      setTimeout(() => {
        dispatch(
          updateFormValues({
            buildingFormsState: currentBuilding?.buildingFormsState || []
          })
        )
      }, 1000)
      const formState = currentBuilding.buildingFormsState?.find(
        item => item.formName === activeForm
      )
      if (isNotNilOrEmpty(formState)) {
        const stateSteps = formState?.steps || []
        const filteredSteps = formSteps.filter(
          step => !stateSteps.includes(step)
        )
        const nextStep = head(filteredSteps)
        const hasNextStep = isNotNilOrEmpty(nextStep)

        const selectedStep = hasNextStep ? nextStep : head(formSteps)

        dispatch(updateFinishedSteps(stateSteps))
        dispatch(
          updateVisibleSteps(
            hasNextStep ? [...stateSteps, nextStep] : stateSteps
          )
        )
        dispatch(setActiveStep(selectedStep))
      } else {
        const firstStep = head(formSteps)
        dispatch(setActiveStep(firstStep))
        dispatch(updateVisibleSteps([firstStep]))
      }
    } else {
      const firstStep = head(formSteps)
      dispatch(setActiveStep(firstStep))
      dispatch(updateVisibleSteps([firstStep]))
    }
  }, [currentBuilding, activeForm])

  const handleSaveAsDraftModalOpen = () => setSaveAsDraftModalOpen(true)
  const handleSaveAsDraftModalClose = () => setSaveAsDraftModalOpen(false)

  const handleClose = () => {
    dispatch(clearActiveForm())
    isNotNilOrEmpty(currentBuilding) && dispatch(getBuildingDetails({ id: currentBuilding.id }))
  }

  const formComponent = useMemo(() => {
    switch (activeForm) {
      case 'add_building':
        return <AddBuildingForm />
      case 'edit_building':
        return <EditBuildingForm />
      case 'plans':
        return <PlansForm />
      case 'wall_design':
        return <WallsForm />
      default:
        return <div />
    }
  }, [activeForm, activeStep])

  const isFormSubmitted = useMemo(() => {
    switch (activeForm) {
      case 'plans':
        return pathOr(null, ['floorPlansStatus', 'value'], currentBuilding) === 'submitted'
      case 'wall_design':
        return pathOr(null, ['wallDesignStatus', 'value'], currentBuilding) === 'submitted'
      case 'add_building':
      case 'edit_building':
      default:
        return false
    }
  }, [activeForm])

  const handleSaveAsDraft = async () => {
    const { errors, formValues } = checkFormBeforeSave(activeForm)
    if (isNotNilOrEmpty(errors)) {
      toast.error(keys(errors).join('\n'))
    } else {
      const payload = isAddBuildingForm
        ? formValues
        : {
          id: currentBuilding.id,
          name: currentBuilding.name,
          ...formValues
        }

      if (isNotNilOrEmpty(formValues)) {
        const response = isAddBuildingForm
          ? await dispatch(addBuilding(payload))
          : await dispatch(editBuilding(payload))
        if (isNotNilOrEmpty(response.payload)) {
          const query = '?limit=100&page=1&sort=-updated_at'
          if (isAddBuildingForm) {
            dispatch(getUserBuildings(query))
          } else {
            isNotNilOrEmpty(currentBuilding) && dispatch(getBuildingDetails({ id: currentBuilding.id }))
          }
          dispatch(clearActiveForm())
        }
        handleSaveAsDraftModalClose()
      } else {
        toast.error('Something went wrong')
      }
    }
  }

  return activeForm ? (
    <>
      <Backdrop onClick={handleClose}>
        <FakeModal onClick={e => e.stopPropagation()}>
          <ModalHeader>
            <ModalTitle>{formTitle}</ModalTitle>
            <ModalActions>
              {
                isFormSubmitted
                  ? (
                    <IconWrapper onClick={handleClose}>
                      <CloseIcon />
                    </IconWrapper>
                  )
                  : (
                    <>
                      <Button
                        variant='secondary'
                        size='medium'
                        onClick={handleSaveAsDraftModalOpen}
                      >
                        Save Draft & Close
                      </Button>
                      <Divider />
                      <CloseFormModal onDraftSave={handleSaveAsDraft} />
                    </>
                  )
              }
            </ModalActions>
          </ModalHeader>
          <ModalContent>
            <StepsPanel>
              <div>
                {activeForm !== 'add_building' && <BuildingDetailsInForm isCollapsible withSmallFont />}
                <SectionTitle>Steps</SectionTitle>
                {isNotNilOrEmpty(formSteps) &&
                formSteps.map(step => (
                  <StepWrapper
                    key={step.key}
                    isVisible={isStepVisible(step.key)}
                    onClick={changeStep(step.key)}
                  >
                    <StepTitle active={activeStep === step.key}>
                      {step.title}
                      {finishedSteps.includes(step.key) && (
                        <CheckIcon fill={theme.colors.primary} />
                      )}
                    </StepTitle>
                  </StepWrapper>
                ))}
              </div>
              {isAddBuildingForm && (
                <BuildingPassportIncentive>
                  <Logo src={logoShort} alt='bp-logo' />
                  <span>Building Passport creation</span>
                  <div>
                    To help speed up future information transfers to the fire
                    service, a new, free Building Passport will also be securely
                    saved as a draft which you (and only you) can access at any
                    time through the following link:
                  </div>
                  <BpLink
                    href='https://app.buildingpassport.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Go to Building Passport
                    <RedirectIcon />
                  </BpLink>
                </BuildingPassportIncentive>
              )}
            </StepsPanel>
            <FormContentWrapper>
              <FormContent>{formComponent}</FormContent>
            </FormContentWrapper>
          </ModalContent>
        </FakeModal>
      </Backdrop>
      <Modal
        open={saveAsDraftModalOpen}
        title='Save as draft'
        onClose={handleSaveAsDraftModalClose}
      >
        Do you want to save the given data?
        <ButtonsWrapper>
          <Button
            variant='secondary'
            onClick={handleSaveAsDraftModalClose}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveAsDraft}>
            Save & Close
          </Button>
        </ButtonsWrapper>
      </Modal>
    </>
  ) : null
}

export default FormModal

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
`

const FakeModal = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
`

const ModalHeader = styled.div`
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafbfc;
  filter: drop-shadow(0px 0px 4px rgba(101, 118, 184, 0.08)) drop-shadow(0px 2px 4px rgba(101, 118, 184, 0.12)) drop-shadow(0px 8px 16px rgba(101, 118, 184, 0.06)) drop-shadow(0px 1px 2px rgba(101, 118, 184, 0.06));
  border-bottom: 1px solid #dce0f2;
  border-radius: 8px 8px 0 0;
`

const ModalTitle = styled.div`
  font-size: 24px;
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.border};
`

const ModalContent = styled.div`
  display: flex;
  height: calc(100% - 80px);
`

const StepsPanel = styled.div`
  min-width: 280px;
  max-width: 280px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 16px;
  height: 100%;
  border-radius: 0 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StepTitle = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ active }) =>
          active &&
          css`
            background-color: ${({ theme }) => theme.colors.background};
            font-weight: bold;
          `}
`

const FormContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
`

const FormContent = styled.div`
  width: 640px;
  margin: 0 auto;
`

const BuildingPassportIncentive = styled.div`
  padding: 22px 16px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.copperBg};
  font-size: 14px;

  span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const Logo = styled.img`
  width: 45px;
  margin-bottom: 20px;
`

const BpLink = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors.copper};
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
`

const RedirectIcon = styled(OpenInNewOutlinedIcon)`
  color: ${({ theme }) => theme.colors.copper};
  font-size: 12px !important;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  gap: 10px;

  button {
    width: fit-content;
  }
`

const StepWrapper = styled.div`
  width: 100%;

  ${({ isVisible }) =>
          isVisible &&
          css`
            font-weight: bold;
            cursor: pointer;
          `}
`

const IconWrapper = styled.div`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.copper};
`
